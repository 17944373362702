import { forwardRef, createElement, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import theme from '../theme/theme';

export type TypographyVariants =
  | 'small'
  | 'body'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'caption';

export type TypographyProps = {
  variant?: TypographyVariants;
  tag?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
  /**
   * Use it the same you would for a div element.
   * **IMPORTANT** don't use this for data that can be generated by users!
   */
  dangerouslySetInnerHTML?: {
    __html: string;
  };
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  'data-testid'?: string;
};

export const getFontSizeForSmallBreakpoint = (
  fontSize: TypographyVariants,
): number =>
  (fontSize === 'h5'
    ? 20
    : fontSize === 'h6'
    ? 18
    : fontSize === 'body'
    ? 16
    : fontSize === 'small'
    ? 12
    : theme.fonts.sizes[fontSize] * 14) / theme.fonts.root.sm;

export const getFontSizeForLargeBreakpoint = (
  fontSize: TypographyVariants,
): number =>
  (fontSize === 'h5'
    ? 20
    : fontSize === 'h6'
    ? 18
    : theme.fonts.sizes[fontSize] * 18) / theme.fonts.root.lg;

export const Typography = forwardRef<
  HTMLDivElement,
  PropsWithChildren<TypographyProps>
>(({ variant = 'body', tag, ...props }, ref) => {
  const elementTag = tag
    ? tag
    : ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)
    ? variant
    : 'div';
  return createElement(elementTag, { ...props, ref });
});

export default styled(Typography)`
  color: ${({ theme }) => theme.colors.TEXT};

  ${({ theme, variant }) => {
    let styles = `
      font-size: ${getFontSizeForSmallBreakpoint(variant!)}rem;
      @media only screen and (min-width: ${theme.breakpoints.md.width}px) {
        font-size: ${theme.fonts.sizes[variant!]}rem;
      }
      
      @media only screen and (min-width: ${theme.breakpoints.lg.width}px) {
        font-size: ${getFontSizeForLargeBreakpoint(variant!)}rem;
      }
    `;

    styles += `
      margin-top: ${
        variant === 'h1' || variant === 'h2'
          ? theme.spacing.lg * 2
          : variant === 'body' || variant === 'small'
          ? 0
          : theme.spacing.md * 2
      }rem;
      margin-bottom: ${
        variant === 'h1' || variant === 'h2'
          ? theme.spacing.lg
          : theme.spacing.md
      }rem;
      line-height: ${
        ['small', 'body'].includes(variant!)
          ? theme.fonts.height.body
          : theme.fonts.height.heading
      };
      font-family: ${
        ['h1', 'h2', 'h3', 'h4'].includes(variant!)
          ? theme.fonts.family.medium
          : theme.fonts.family.regular
      };
      letter-spacing: ${
        ['h1', 'h2', 'h6'].includes(variant!) ? 'initial' : '0.8px'
      };
      text-transform: ${
        ['caption', 'h6'].includes(variant!) ? 'uppercase' : 'none'
      };
    `;
    return styles;
  }}

  & span {
    background: ${({ theme }) => theme.gradients.YELLOW_TO_GREEN_TO_BLUE};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & b,
  & strong {
    font-family: ${({ theme }) => theme.fonts.family.semiBold};
  }

  /* The temporary solution until the correct tagging appears in phrase PC-731 */
  & p {
    ${({ theme, variant }) => css`
      line-height: ${({ theme }) => theme.fonts.height.body};
      margin-bottom: ${({ theme }) => theme.spacing.md}rem;
      margin-top: ${({ theme }) => theme.spacing.md}rem;
      font-family: ${({ theme }) => theme.fonts.family.regular};
      font-size: ${getFontSizeForSmallBreakpoint('body')}rem;

      @media only screen and (min-width: ${theme.breakpoints.md.width}px) {
        font-size: ${theme.fonts.sizes[variant!]}rem;
      }
    `}

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-top: 0;
    }
  }

  & h3,
  & h4,
  & h5 {
    line-height: ${({ theme }) => theme.fonts.height.heading};
    font-family: ${({ theme }) => theme.fonts.family.medium};
  }
  & h3,
  & h4,
  & h5 {
    margin-top: ${({ theme }) => theme.spacing.md * 2}rem;
    margin-bottom: ${({ theme }) => theme.spacing.md}rem;
  }
  & p + h4 {
    margin-top: ${({ theme }) => theme.spacing.xl}rem;
  }
  & h3 {
    font-size: ${({ theme }) => theme.fonts.sizes.h3}rem;
  }
  & h4 {
    font-size: ${({ theme }) => theme.fonts.sizes.h4}rem;
  }
  & h5 {
    font-size: ${getFontSizeForSmallBreakpoint('h5')}rem;

    ${({ theme }) => css`
      @media only screen and (min-width: ${theme.breakpoints.md.width}px) {
        font-size: ${({ theme }) => theme.fonts.sizes.h5}rem;
      }
      @media only screen and (min-width: ${theme.breakpoints.lg.width}px) {
        font-size: ${getFontSizeForLargeBreakpoint('h5')}rem;
      }
    `}
  }

  & h6 {
    font-size: ${getFontSizeForSmallBreakpoint('h6')}rem;

    ${({ theme }) => css`
      @media only screen and (min-width: ${theme.breakpoints.md.width}px) {
        font-size: ${({ theme }) => theme.fonts.sizes.h6}rem;
      }
      @media only screen and (min-width: ${theme.breakpoints.lg.width}px) {
        font-size: ${getFontSizeForLargeBreakpoint('h6')}rem;
      }
    `}
  }
`;
