import { PersonalityTestSection, PersonalityTestType } from './PersonalityTest';

export const INTRODUCTION = 'introduction';
export const QUESTIONNAIRE = 'questionnaire';
export const QUESTION = 'question';
export const SIGNUP = 'sign-up';
export const SWITCH = 'switch';
export const CHECK_EMAIL = 'check-email';
export const NEXT_STEP = 'next-step';
export const RESULT = 'results';
export const PROCESSING = 'processing';

export const SLUG_PAGE = 'page';
export const SLUG_QUESTION_INDEX = 'questionIndex';
export const SLUG_SECTION = 'section';
export const SLUG_TYPE = 'type';
export const SLUG_TRESULT_ID = 'resultId';
export const SLUG_RESULT_PAGE_SECTION = 'resultPageSection';

export const ROUTE_TEST = `/:${SLUG_TYPE}`;
export const ROUTE_PAGE = `/:${SLUG_TYPE}/:${SLUG_PAGE}`;
export const ROUTE_INTRO = `/:${SLUG_TYPE}/:${INTRODUCTION}`;
export const ROUTE_QUESTIONNAIRE = `/:${SLUG_TYPE}/${QUESTIONNAIRE}/:${SLUG_SECTION}/${QUESTION}/:${SLUG_QUESTION_INDEX}?`;
export const ROUTE_SWITCH = `/:${SLUG_TYPE}/${SWITCH}`;
export const ROUTE_SIGNUP = `/:${SLUG_TYPE}/${SIGNUP}`;
export const ROUTE_CHECK_EMAIL = `/:${SLUG_TYPE}/${CHECK_EMAIL}`;
export const ROUTE_RESULT = `/:${SLUG_TYPE}/${RESULT}/:${SLUG_TRESULT_ID}/:${SLUG_RESULT_PAGE_SECTION}?`;

export const ROUTE_SHORT_STANDARD = `/${PersonalityTestType.shortStandard}`;
export const ROUTE_SHORT_COACH = `/${PersonalityTestType.shortCoach}`;
export const ROUTE_SHORT_ENTREPRENEUR = `/${PersonalityTestType.shortEntrepreneur}`;
export const ROUTE_PRO_STANDARD = `/${PersonalityTestType.proStandard}`;
export const ROUTE_PRO_COACH = `/${PersonalityTestType.proCoach}`;

// Relative paths to be used inside a test (`/short`, `/pro`, etc...)
export const RELATIVE_PATH_TO_INTRO = `/${INTRODUCTION}`;
export const RELATIVE_PATH_TO_MOST_APPLICABLE = `/${QUESTIONNAIRE}/${PersonalityTestSection.mostApplicable}/${QUESTION}`;
export const RELATIVE_PATH_TO_SWITCH = `/${SWITCH}`;
export const RELATIVE_PATH_TO_LEAST_APPLICABLE = `/${QUESTIONNAIRE}/${PersonalityTestSection.leastApplicable}/${QUESTION}`;
export const RELATIVE_PATH_TO_PROCESSING = `/${PROCESSING}`;
export const RELATIVE_PATH_TO_SIGNUP = `/${SIGNUP}`;
export const RELATIVE_PATH_TO_CHECK_EMAIL = `/${CHECK_EMAIL}`;
