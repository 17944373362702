import { FunctionComponent, Children } from 'react';
import { Redirect, Switch, SwitchProps, useRouteMatch } from 'react-router-dom';
import TestRoute, { TestRouteElement } from './TestRoute';

const areTestRouteChildren = (
  children: unknown,
): children is Array<TestRouteElement> => {
  for (const child of children as Array<TestRouteElement>) {
    if (child.type !== TestRoute) {
      return false;
    }
  }
  return true;
};

interface TestSwitchProps extends SwitchProps {
  children?: any;
  unlockAllRoutes?: boolean;
}

const TestSwitch: FunctionComponent<TestSwitchProps> = ({
  unlockAllRoutes,
  children,
}) => {
  const { path } = useRouteMatch();
  const childrenAsArray = Children.toArray(children);
  if (!areTestRouteChildren(childrenAsArray)) {
    throw new Error("TestSwitch children should be of type 'TestRoute'");
  }

  const childrenWithNextRoute: TestRouteElement[] = childrenAsArray.map(
    ({ props, ...child }, index) => ({
      ...child,
      props: {
        ...props,
        next:
          props.next ??
          childrenAsArray[(index + 1) % childrenAsArray.length].props.path,
      },
    }),
  );

  const acessibleChildren = childrenWithNextRoute.filter(
    ({ props: { accessible = true, path } }) => accessible && path,
  );

  const firstPagePath = acessibleChildren[0]?.props.path ?? path;
  const lastAvailablePath =
    acessibleChildren[acessibleChildren.length - 1]?.props.path ?? path;

  const childrenToRender = unlockAllRoutes
    ? childrenWithNextRoute
    : acessibleChildren;

  const renderRedicects = childrenToRender.length > 0;
  return (
    <Switch>
      {childrenToRender}
      {renderRedicects && <Redirect exact from={path} to={firstPagePath} />}
      {renderRedicects && <Redirect to={lastAvailablePath} />}
    </Switch>
  );
};

export default TestSwitch;
