import styled, {
  useTheme,
  ThemedStyledProps,
  DefaultTheme,
} from 'styled-components';
import mergeRefs from 'react-merge-refs';
import {
  cloneElement,
  createElement,
  forwardRef,
  useCallback,
  useEffect,
  useState,
  Children,
  PropsWithChildren,
} from 'react';
import useResize from '../../hooks/useResize';
import Col, { ColProps } from './Col';
import useRefCallback from '../../hooks/useRefCallback';

export interface RowProps {
  /**
   * If true, items inside a row will apply container width  for
   * positioning instead of breakpoints.
   */
  container?: boolean;

  /**
   * Controls horizontal seperation between the columns
   * 'wide' - Columns that are adjacent will have full width of gutter.
   * 'narrow' - Columns that are adjacent will have half width of gutter.
   * 'condensed' - Columns that are adjacent will have no gutters.
   * */
  horizontalSpacing?: 'wide' | 'narrow' | 'condensed';

  /**
   * Controls vertical seperation between columns.
   * 'wide' - Columns have full vertical padding.
   * 'narrow' - Columns have half the the vertical padding between them.
   * 'condensed' - No vertical padding is added.
   * */
  verticalSpacing?: 'wide' | 'narrow' | 'condensed';

  /** Controls both horizontal and vertical space size */
  spacing?: 'small' | 'medium' | 'large';
  tag?: 'section' | 'article' | 'div';
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
}

export const BaseRow = forwardRef<HTMLDivElement, PropsWithChildren<RowProps>>(
  (
    {
      container,
      tag = 'div',
      verticalSpacing,
      horizontalSpacing,
      spacing,
      children,
      ...props
    },
    forwardedRef,
  ) => {
    const [currentRef, setCurrentRef] = useRefCallback();
    const [refWidth, setRefWidth] = useState(0);
    const { breakpointList, breakpoints } = useTheme();
    const reversedBreakPointList = breakpointList.slice().reverse();
    const containerModeDataBp = reversedBreakPointList.find(
      (key) => breakpoints[key].width <= refWidth,
    );
    const updateSize = useCallback(() => {
      if (currentRef) {
        setRefWidth(currentRef.offsetWidth);
      }
    }, [currentRef]);

    useEffect(updateSize, [updateSize]);
    useResize(updateSize);

    return createElement(
      tag,
      {
        ...props,
        'data-bp': container ? containerModeDataBp : undefined,
        ref: mergeRefs([forwardedRef, setCurrentRef]),
      },
      Children.map(children, (child, index) => {
        return cloneElement(child as React.ReactElement<ColProps>, {
          container: container,
        });
      }),
    );
  },
);

const getVerticalSpacing = (
  props: ThemedStyledProps<RowProps, DefaultTheme>,
): number => {
  switch (props.verticalSpacing) {
    case 'wide':
      return props.theme.gutters[props.spacing!];
    case 'narrow':
      return props.theme.gutters[props.spacing!] / 2;
    case 'condensed':
    default:
      return 0;
  }
};

const Row = styled(BaseRow)<RowProps>`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-left: ${(props) =>
    props.horizontalSpacing === 'wide'
      ? `-${props.theme.gutters[props.spacing!]}rem`
      : 0};
  margin-right: ${(props) =>
    props.horizontalSpacing === 'condensed'
      ? 0
      : `-${props.theme.gutters[props.spacing!]}rem`};
  & > ${Col} {
    padding-left: ${(props) =>
      props.horizontalSpacing === 'wide'
        ? `${props.theme.gutters[props.spacing!]}rem`
        : 0};
    padding-right: ${(props) =>
      props.horizontalSpacing === 'condensed'
        ? 0
        : `${props.theme.gutters[props.spacing!]}rem`};
    padding-top: ${(props) => getVerticalSpacing(props)}rem;
    padding-bottom: ${(props) => getVerticalSpacing(props)}rem;
  }
`;

Row.defaultProps = {
  container: false,
  verticalSpacing: 'condensed',
  horizontalSpacing: 'wide',
  spacing: 'medium',
};

export default Row;
