import { useState, useEffect } from 'react';

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * API to determine if it matches with the current document.
 * It also monitor the document changes to detect when it matches or stops matching the media query.
 * Returns the validity state of the given media query.
 *
 * Lightly adapted from:
 * <https://github.com/beautifulinteractions/beautiful-react-hooks/blob/master/src/useMediaQuery.js>
 *
 */
const useMediaQuery = (mediaQuery: string): boolean => {
  const [isVerified, setIsVerified] = useState(
    window.matchMedia(mediaQuery).matches,
  );

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches);
    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', documentChangeHandler);
    } else {
      // for ie11 compatibility
      mediaQueryList.addListener(documentChangeHandler);
    }

    documentChangeHandler();
    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', documentChangeHandler);
      } else {
        mediaQueryList.removeListener(documentChangeHandler);
      }
    };
  }, [mediaQuery]);

  return isVerified;
};

export default useMediaQuery;
