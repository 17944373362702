import { Question } from 'typings/PersonalityTest';
import { PersonalityTestAction, ActionType } from 'typings/Actions';

const questionsReducer = (
  questions: Question[],
  action: PersonalityTestAction,
): Question[] => {
  switch (action.type) {
    case ActionType.UpdateAnswer:
      return questions.map((question) => {
        if (question.questionId !== action.payload.questionId) {
          return question;
        }
        return {
          ...question,
          selected: action.payload.answerIndex,
        };
      });
    case ActionType.ResetTest:
      return questions.map((question) => {
        return {
          ...question,
          selected: undefined,
        };
      });
    default:
      return questions;
  }
};

export default questionsReducer;
