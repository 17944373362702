import axios from 'axios';
import { getEnvironmentVariable } from 'utils/environment';
import { EnvironmentVariables } from 'typings/constants';
import { ConfigInterface } from 'swr';
import { ApiError } from 'typings/errors';

const globalSwrConfig: ConfigInterface<
  unknown,
  unknown,
  (...args: any) => unknown
> = {
  fetcher: async (url: string, ...params) => {
    let response;
    let apiError: ApiError;
    try {
      response = await axios({
        baseURL: getEnvironmentVariable(EnvironmentVariables.apiDomain),
        method: 'GET',
        url,
        params,
      });
    } catch (error) {
      try {
        // @ts-ignore
        apiError = new ApiError(error);
      } catch {
        // failure to parse Api response;
      }
    }
    if (response?.data?.data) {
      return response.data.data;
    }
    if (apiError!) {
      throw apiError;
    }
    throw new Error('Failure to parse API response');
  },
};

export default globalSwrConfig;
