import styled, { useTheme } from 'styled-components';
import {useTestRouteParams} from "../../../contexts/testRouter";
import {PersonalityTestType} from "../../../typings/PersonalityTest";
import logo from 'assets/images/logoGradient.svg';
import logoEntrepreneur from 'assets/images/logoEntrepreneurWhite.png';

const StyledHeader = styled.header`
  position: fixed;
  z-index: 15;
  padding: 0;
  background: ${({ theme }) => theme.colors.WHITE};
  height: ${({ theme }) => theme.sizes.headerHeight}px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};

  > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: ${({ theme }) => theme.breakpoints.md.width}px;
    margin: 0 auto;
  }
`;

const StyledHeaderEntrepreneur = styled(StyledHeader)`
  background-image: ${({ theme }) => theme.gradients.DARK_BLUE_TO_LIGHT_BLUE};
  height: ${({ theme }) => theme.sizes.headerHeightEntrepreneur}px;
`;


const LogoImage = styled.img`
  margin: 16px auto 0;
  height: 24px;
  width: 89px;
  display: block;
`;

const LogoImageEntrepreneur = styled.img`
  margin: 16px auto 0;
  max-height: 30px;
  max-width: 200px;
  display: block;
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.fonts.family.semiBold};
  height: 19px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 1px;
  padding: 0;
  margin: 0 auto 16px;
  word-wrap: break-word;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.DARK_GRAY};
`;

export type MainHeaderProps = {
  title?: string;
};

function Header(props: any) {
    const { type } = useTestRouteParams();

    if (type === PersonalityTestType.shortEntrepreneur) {
        return <StyledHeaderEntrepreneur>
                <div>
                    {logoEntrepreneur && <LogoImageEntrepreneur src={logoEntrepreneur} alt={'Greator'} />}
                </div>
            </StyledHeaderEntrepreneur>;
    }
    return <StyledHeader>
        <div>
            {logo && <LogoImage src={logo} alt={'Greator'} />}
            <Title>{props.title}</Title>
        </div>
    </StyledHeader>;
}

const MainHeader: React.FunctionComponent<MainHeaderProps> = ({
  title,
}) => {
    const { sizes } = useTheme();
  return (
    <>
        <Header logo={logo} title={title} sizes={sizes}></Header>
        <div style={{ height: sizes.headerHeight }} />
    </>
  );
};

export default MainHeader;
