import styled from "styled-components";
import {useTranslation} from "react-i18next";

export type MainFooterProps = {};

const StyledFooter = styled.footer`
  background-image: ${({ theme }) => theme.gradients.DARK_BLUE_TO_LIGHT_BLUE};
  min-height: 72px;
`;

const InnerWrapper = styled.div`
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Columns = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
`;

const LogoWrapper = styled.div`
  flex-wrap: nowrap;
  padding: 10px 16px;
  width: 10%;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakpoints.lg.width}px) {
    & {
      width: 100%;
    }
  }
`;

const LinkWrapper = styled.div`
  flex-wrap: nowrap;
  padding: 16px;
  width: 50%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({theme}) => theme.breakpoints.lg.width}px) {
    & {
      width: 100%;
    }
  }

  @media (max-width: ${({theme}) => theme.breakpoints.md.width}px) {
    & {
      display: block;
      text-align: center;
    }
  }
`;

const SocialMediaWrapper = styled.div`
  flex-wrap: nowrap;
  padding: 16px;
  width: 30%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  align-items: center;

  @media (max-width: ${({theme}) => theme.breakpoints.lg.width}px) {
    & {
      justify-content: center;
      width: 100%;
    }
  }
`;

const Logo = styled.img`
  vertical-align: top;
  height: auto;
  max-width: 100%;
  width: 52px;
`;

const Link = styled.a`
  text-decoration: none;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  margin-right: 24px;
  letter-spacing: 0.6px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.md.width}px) {
    & {
      padding: 4px 0;
      width: 100%;
    }
  }
`;

const Copyright = styled.div`
  font-size: 12px;
  letter-spacing: 0.6px;
  margin-right: 24px;
  color: #ffffff;
`;

const SocialMediaLink = styled.a`
  touch-action: manipulation;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0064d2;
`;

const SocialMediaLinkIcon = styled.img`
  vertical-align: top;
  height: auto;
  max-width: 100%;
  width: 40px;
  margin-right: 20px;
`;

const MainFooter: React.FunctionComponent<MainFooterProps> = () => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <>
                <InnerWrapper>
                    <Columns>
                        <LogoWrapper>
                            <Logo height="150" width="150"
                                  alt=""
                                  src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_G_White_RGB-150x150.png"
                                  sizes="(max-width: 150px) 100vw, 150px"
                                  data-src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_G_White_RGB-150x150.png"
                                  data-srcset=""/>
                        </LogoWrapper>
                        <LinkWrapper>
                            <Link href="https://greator.com/presse/"
                                  target="_self">Presse</Link>
                            <Link href="https://greator.com/datenschutz/"
                                target="_self">Datenschutz</Link>
                            <Link href="https://greator.com/impressum/"
                                  target="_self">Impressum</Link>
                            <Link href="https://greator.com/agb/"
                                  target="_self">AGB</Link>
                            <Copyright>
                                {t('questionnairePage.entrepreneur.copyright', {
                                    year: new Date().getFullYear(),
                                })}
                            </Copyright>
                        </LinkWrapper>
                        <SocialMediaWrapper>
                            <SocialMediaLink href="https://greator.link/fb" target="_self">
                                <SocialMediaLinkIcon height="300"
                                                     width="300"
                                                     alt="Facebook Logo"
                                                     src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Facebook_Line_White.svg"
                                                     sizes="(max-width: px) 100vw, px"
                                                     data-src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Facebook_Line_White.svg"
                                                     data-srcset=""/>
                            </SocialMediaLink>
                            <SocialMediaLink href="https://greator.link/ig" target="_self">
                                <SocialMediaLinkIcon
                                    height="300" width="300" id="image-176-2011" alt="Instagram Logo"
                                    src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Instagram_Line_White.svg"
                                    data-src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Instagram_Line_White.svg"
                                    data-srcset=""/>
                            </SocialMediaLink>
                            <SocialMediaLink href="https://greator.link/yt" target="_self">
                                <SocialMediaLinkIcon height="300" width="300"
                                                     alt="YouTube Logo"
                                                     src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Video_Youtube_Clip_Line_White.svg"
                                                     sizes="(max-width: px) 100vw, px"
                                                     data-src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Video_Youtube_Clip_Line_White.svg"
                                                     data-srcset=""/>
                            </SocialMediaLink>
                            <SocialMediaLink href="https://greator.link/spotify" target="_self">
                                <SocialMediaLinkIcon
                                    height="300" width="300" alt="Spotify Logo"
                                    src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Music_Spotify_Line_White.svg"
                                    data-src="https://personality.greator.com/wp-content/uploads/sites/2/2020/05/Greator_Icon_Social_Music_Spotify_Line_White.svg"
                                    data-srcset=""/>
                            </SocialMediaLink>
                        </SocialMediaWrapper>
                    </Columns>
                </InnerWrapper>
            </>
        </StyledFooter>
    );
};

export default MainFooter;
