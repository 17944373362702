import {
  Participant,
  Questions,
  PersonalityTraitIndex,
  PersonalityTestType,
} from 'typings/PersonalityTest';

export enum ActionType {
  UpdateAnswer,
  SetQuestions,
  ResetTest,
  SetParticipant,
  SetVisitorId,
}

export type AnswerPayload = {
  questionId: number;
  answerIndex: PersonalityTraitIndex;
};

type UpdateAnswerAction = {
  readonly type: ActionType.UpdateAnswer;
  readonly payload: AnswerPayload;
};

type SetQuestionsAction = {
  readonly type: ActionType.SetQuestions;
  readonly payload: {
    questions: Questions;
    type: PersonalityTestType;
  };
};

type SetParticipantAction = {
  readonly type: ActionType.SetParticipant;
  readonly payload?: Participant;
};

type SetVisitorIdAction = {
  readonly type: ActionType.SetVisitorId;
  readonly payload?: string;
};

type ResetTestAction = {
  readonly type: ActionType.ResetTest;
};

export type PersonalityTestAction =
  | UpdateAnswerAction
  | SetQuestionsAction
  | ResetTestAction
  | SetParticipantAction
  | SetVisitorIdAction;
