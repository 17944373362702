import { forwardRef } from 'react';
import styled, { CSSProperties } from 'styled-components';

export type LoadingIndicatorProps = {
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: CSSProperties;
};

const LoadingIndicator = styled(
  forwardRef<HTMLDivElement, React.PropsWithChildren<LoadingIndicatorProps>>(
    ({ className, id, 'data-testid': testId, style }, ref) => {
      return (
        <div
          ref={ref}
          className={className}
          id={id}
          data-testid={testId}
          style={style}>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="https://www.w3.org/2000/svg"
            stroke="#2565CB">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle
                  strokeOpacity=".15"
                  cx="18"
                  cy="18"
                  r="18"
                  stroke="#555"
                />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </div>
      );
    },
  ),
)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export default LoadingIndicator;
