export const getEnvironmentVariable = (
  variableName: string,
  defaultValue?: string,
): string => {
  const value = process.env[variableName] || defaultValue;
  if (value === undefined) {
    throw new Error(`Missing environment variable [${variableName}].`);
  }
  return value;
};
