import { useCallback, useEffect, useRef } from 'react';

/**
 * @return a getter that returns true after the component is
 * unmounted
 */
const useComponentUnmounted = (): (() => boolean) => {
  const mountedRef = useRef(false);
  const get = useCallback(() => mountedRef.current, []);
  useEffect(
    () => () => {
      mountedRef.current = true;
    },
    [],
  );
  return get;
};

export default useComponentUnmounted;
