import { useEffect } from 'react';

/**
 * Smooth scrolls a relative ammount of pixels.
 * Scroll event duration and "smoothness" is controlled by the browser
 *
 * @param value The relative position to scroll to
 * @param callback called when the scrolling event is completed
 */
const scrollTo = (value: number, callback?: () => void): void => {
  if (callback) {
    let callCallback: number;
    const onScroll = () => {
      if (callCallback) {
        window.clearTimeout(callCallback);
      }
      callCallback = window.setTimeout(() => {
        window.removeEventListener('scroll', onScroll);
        callback();
      }, 25);
    };
    window.addEventListener('scroll', onScroll);
  }
  window.scrollTo({ top: value, behavior: 'smooth' });
};

/**
 * Smooth scrolls to the bottom of the page.
 *
 * @param callback called when the scrolling event is completed
 */
const scrollToBottom = (callback?: () => void): void => {
  scrollTo(document.body.scrollHeight, callback);
};

/**
 * Supplies scrolling functions to the current component, as well
 * as callback for scroll events.
 *
 * @param callback called when a scroll event is detected by the `window`
 */
function useScroll(callback?: (e: Event) => void) {
  useEffect(() => {
    if (callback) {
      const onScrollCallback = (e: Event) => callback(e);
      window.addEventListener('scroll', onScrollCallback);
      return () => window.removeEventListener('scroll', onScrollCallback);
    }
  }, [callback]);

  return {
    scrollTo,
    scrollToBottom,
  };
}

export default useScroll;
