import { forwardRef } from 'react';
import ButterflyWing from './ButterflyWing';
import ButterflyBackground from './ButterflyBackground';
import { Quadrant, REFERENCE_SIZE } from './types';
import styled from 'styled-components';

export type ButterflyGraphProps = {
  /** Number between 0 and 100 */
  northEastValue: number;
  northEastTitle: string;
  northEastColor: string;

  /** Number between 0 and 100 */
  southEastValue: number;
  southEastTitle: string;
  southEastColor: string;

  /** Number between 0 and 100 */
  northWestValue: number;
  northWestTitle: string;
  northWestColor: string;

  /** Number between 0 and 100 */
  southWestValue: number;
  southWestTitle: string;
  southWestColor: string;

  onClick?: (e: React.MouseEvent, quadrant: Quadrant) => void;
  className?: string;
  style?: React.CSSProperties;
  'data-testid'?: string;
};

const ButterflyGraph = forwardRef<SVGSVGElement, ButterflyGraphProps>(
  (
    {
      northEastTitle,
      northEastValue,
      northEastColor,
      southEastTitle,
      southEastValue,
      southEastColor,
      northWestTitle,
      northWestValue,
      northWestColor,
      southWestTitle,
      southWestValue,
      southWestColor,
      'data-testid': dataTestId,
      className,
      style,
      onClick,
    },
    ref,
  ) => {
    const lowestValueQuadrant: Quadrant = [
      [northEastValue, Quadrant.NE],
      [northWestValue, Quadrant.NW],
      [southEastValue, Quadrant.SE],
      [southWestValue, Quadrant.SW],
    ].sort((a, b) => a[0] - b[0])[0][1];

    // NOTE: The order of the wings matters because of shadows. Shadows are
    // cast to the south-west, so we have to put  north wings before south
    // wings, and western wings before eastern wings.
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-testid={dataTestId}
        className={className}
        style={style}
        ref={ref}
        viewBox={`
        -${REFERENCE_SIZE / 2} 
        -${REFERENCE_SIZE / 2} 
        ${REFERENCE_SIZE} 
        ${REFERENCE_SIZE}`}>
        <ButterflyBackground
          startRadius={40}
          endRadius={80}
          quadrant={lowestValueQuadrant}
        />
        <ButterflyWing
          quadrant={Quadrant.NW}
          onClick={onClick}
          stroke={northWestColor}
          fill={northWestColor}
          size={northWestValue}
          title={northWestTitle}
        />
        <ButterflyWing
          quadrant={Quadrant.SW}
          onClick={onClick}
          stroke={southWestColor}
          fill={southWestColor}
          size={southWestValue}
          title={southWestTitle}
        />
        <ButterflyWing
          quadrant={Quadrant.NE}
          onClick={onClick}
          stroke={northEastColor}
          fill={northEastColor}
          size={northEastValue}
          title={northEastTitle}
        />
        <ButterflyWing
          quadrant={Quadrant.SE}
          onClick={onClick}
          stroke={southEastColor}
          fill={southEastColor}
          size={southEastValue}
          title={southEastTitle}
        />
      </svg>
    );
  },
);

const StyledButterflyGraph = styled(ButterflyGraph)`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
  border-radius: ${REFERENCE_SIZE / 16}px;
  padding: ${({ theme }) => theme.spacing.sm}rem;
`;

export default StyledButterflyGraph;
