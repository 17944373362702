import { Locale, Dimension, Color, SESSION_VISITOR_ID_KEY } from './constants';

export enum PersonalityTestType {
  shortStandard = 'short',
  shortCoach = 'coach',
  shortEntrepreneur = 'entrepreneur',
  proStandard = 'pro',
  proCoach = 'pro-coach',
}

export enum PersonalityTestSection {
  leastApplicable = 'least-applicable',
  mostApplicable = 'most-applicable',
}

export enum PersonalityTestContext {
  standard = 'standard',
  coach = 'coach',
  entrepreneur = 'entrepreneur',
}
export type PersonalityTraitIndex = number;

export interface PersonalityTrait {
  dimension?: string; //"D" | "I" | "C" | "S" | "";
  personalityTraitId: string;
  translations: {
    [key in Locale]: string;
  };
}

export interface Question {
  questionId: number;
  personalityTraits: Array<PersonalityTrait>;
  selected?: PersonalityTraitIndex;
}

/** Expected return type from question service API */
export interface Questions {
  leastApplicable: Array<Question>;
  mostApplicable: Array<Question>;
}

export interface Answer {
  questionId: number;
  personalityTrait: {
    personalityTraitId: string;
    dimension?: string;
    translation: string;
  };
}

/** Expected body for result service */
export interface Answers {
  mostApplicable: Array<Answer>;
  leastApplicable: Array<Answer>;
}

export interface PersonalityTestState {
  /** Defines the type of test currently in progress. undefined if no test in progress */
  type?: PersonalityTestType;
  questions: Questions;
  participant?: Participant;
  [SESSION_VISITOR_ID_KEY]?: string;
}

export interface Participant {
  email: string;
  firstName: string;
  locale: Locale;
}

export interface ResultResponsePayload {
  resultId: string;
  participantId: string;
  createdAt: string;
  updatedAt: string;
  answers: Answers;
  personalityProfile: PersonalityProfile;
  type: string;
  product: string;
  locale: string;
}

export interface ResultResponse {
  data: ResultResponsePayload;
}

export interface PersonalityProfile {
  color: Color;
  description: string;
  pattern: number;
  dimensions: {
    [Dimension.Dominant]: DimensionData;
    [Dimension.Influencing]: DimensionData;
    [Dimension.Steady]: DimensionData;
    [Dimension.Cautious]: DimensionData;
  };
}

export interface DimensionData {
  key: Dimension;
  id: number;
  points: number;
  intensity: number;
}
