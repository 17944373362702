import TagManager from 'react-gtm-module';

import { getEnvironmentVariable } from 'utils/environment';
import * as PTEvent from 'typings/events';
import { Color, TrackingEvent } from 'typings/constants';
import { Dimensions } from '../typings/ProResult';
import Cookie from 'universal-cookie';

interface TrackingProps {
  gtmId: string;
}

export default class TrackingService {
  private static trackingProps: TrackingProps = { gtmId: '' };

  private constructor() {}

  private static track(
    event: PTEvent.GenericTrackingEvent | PTEvent.PersonalityProfileDataLayer,
  ) {
    if (!TrackingService.trackingProps.gtmId) {
      throw new Error('Tried to use tag manager without initializing it');
    }
    setTimeout(() => TagManager.dataLayer({ dataLayer: event }));
  }

  public static initialize() {
    const gtmId = getEnvironmentVariable('REACT_APP_GTM_ID');
    if (TrackingService.trackingProps.gtmId) {
      throw new Error(
        'TrackingService should only be initialized once and has already been initialized',
      );
    }
    TagManager.initialize({ gtmId });
    TrackingService.trackingProps = { gtmId };
  }

  public static trackLanguageSelect(language: string, buttonText: string) {
    TrackingService.track({
      event: TrackingEvent.languageSelect,
      action: `selectedLanguage-${language}`,
      label: `Button ${buttonText} clicked`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackQuestionSelected(
    questionIndex: number,
    answerIndex: number,
  ) {
    TrackingService.track({
      event: TrackingEvent.questionSelect,
      action: `questionSelected-${questionIndex}`,
      label: `answer ${answerIndex}`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackQuestionSelectedChanged(
    questionIndex: number,
    answerIndex: number,
  ) {
    TrackingService.track({
      event: TrackingEvent.questionSelectChange,
      action: `questionSelectedChanged-${questionIndex}`,
      label: `answer ${answerIndex}`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackCtaPressed() {
    TrackingService.track({
      event: TrackingEvent.ctaPressed,
      action: `ctaPressed`,
      label: `call to action pressed`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackVisitorId(visitorId: string) {
    TrackingService.track({
      visitorId,
    } as PTEvent.PersonalityProfileDataLayer);
  }

  public static getTrackingParams() {
    const cookie = new Cookie();

    const parametersToTrack = [
      ['cid', 'CID'],
      ['utm_campaign', 'gr_utm_campaign'],
      ['utm_medium', 'gr_utm_medium'],
      ['utm_source', 'gr_utm_source'],
      ['utm_term', 'gr_utm_term'],
      ['utm_content', 'gr_utm_content'],
      ['utm_adid', 'gr_utm_adid'],
      ['utm_campainid', 'gr_utm_campainid'],
    ];

    const trackingObject: Record<string, string> = {};

    parametersToTrack.forEach((parameterNames) => {
      const cookieValue = cookie.get(parameterNames[1]);
      if (
        cookieValue &&
        typeof cookieValue !== 'undefined' &&
        cookieValue !== ''
      ) {
        trackingObject[parameterNames[0]] = cookieValue;
      }
    });
    return trackingObject;
  }

  public static trackPersonalityInterpretation(
    personalityColor: Color,
    dimensions: Dimensions,
  ) {
    TrackingService.track({
      personalityColor,
      dominant: dimensions.D.intensity,
      influencing: dimensions.I.intensity,
      steady: dimensions.S.intensity,
      cautious: dimensions.C.intensity,
    } as PTEvent.PersonalityProfileDataLayer);
  }

  public static trackModalVisible() {
    TrackingService.track({
      event: TrackingEvent.modalVisible,
      action: `OpenTestModal`,
      label: `Modal got visible`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackModalBack() {
    TrackingService.track({
      event: TrackingEvent.modalBack,
      action: `CloseTestModalBackButton`,
      label: `Modal back to test button pressed`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackModalCancel() {
    TrackingService.track({
      event: TrackingEvent.modalCancel,
      action: `CloseTestModalCancelTestButton`,
      label: `Modal cancel test button pressed`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackNavigation(action: string, label?: string) {
    TrackingService.track({
      event: TrackingEvent.navigation,
      action,
      label,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackCopyUrlButton() {
    TrackingService.track({
      event: TrackingEvent.resultShare,
      action: 'Copy result page url button pressed',
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackSocialShareResultButton(platform: string) {
    TrackingService.track({
      event: TrackingEvent.resultShare,
      action: `Share result button for ${platform} pressed`,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackDownloadResultPageButton() {
    TrackingService.track({
      event: TrackingEvent.resultShare,
      action: 'Download result page button pressed',
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackErrorFallback(error: string) {
    TrackingService.track({
      event: TrackingEvent.errorFallback,
      action: error,
    } as PTEvent.GenericTrackingEvent);
  }

  public static trackErrorFallbackCta(url: string) {
    TrackingService.track({
      event: TrackingEvent.errorFallbackCta,
      action: `trying to recover on ${url}`,
    } as PTEvent.GenericTrackingEvent);
  }
}
