import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import HamburgerButton from "./HamburgerButton";
import useResize from "../hooks/useResize";
import useRefCallback from "../hooks/useRefCallback";

export type NavigationHamburgerProps = {
  activePageId?: string;
  showModal?: boolean;
  /**
   * Called when the hamburger button is clicked, when you click and option,
   * or when the modal is open and you click outside it.
   */
  onModalToggle?: (showModal: boolean) => void;
  /**
   * The navigation buttons are `a` elements, with `href` equal to the page id.
   * Use `preventDefault` in the native click event if you wish to supress the
   * automatic link follow through
   */
  onPageClicked?: (
    e: React.MouseEvent<HTMLAnchorElement>,
    pageId: string
  ) => void;
  pages?: Array<{
    /** Used as `href` for nav anchor entries */
    id: string;
    title: string;
  }>;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  "data-testid"?: string;
};

export const HAMBURGER_BUTTON_TITLE = "toggle-nav";

export const BaseNavigationHamburger: React.FunctionComponent<NavigationHamburgerProps> = ({
  activePageId = "",
  pages = [],
  showModal = false,
  onModalToggle = () => {},
  onPageClicked = () => {},
  ...props
}) => {
  const [headerRef, setHeaderRef] = useRefCallback<HTMLDivElement>();
  const navContainerRef = useRef<HTMLElement>(null);
  const [maxHeight, setMaxHeight] = useState(200);
  const [headerWidth, setHeaderWidth] = useState(500);
  const [headerHeight, setHeaderHeight] = useState(500);

  const updateDimensions = () => {
    if (headerRef && navContainerRef.current) {
      const { height, width } = headerRef.getBoundingClientRect();
      const navChildrenHeight = Array.from(
        navContainerRef.current.children
      ).reduce((sum, e) => sum + e.getBoundingClientRect().height, 0);
      setMaxHeight(navChildrenHeight);
      setHeaderHeight(height);
      setHeaderWidth(width);
    }
  };

  // TODO this solution assumes top level navigation,
  // which might not be always true!
  useEffect(
    function disableScrollWhileShowingModal() {
      if (showModal) {
        window.scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "inital";
      }
      return () => {
        document.body.style.overflow = "initial";
      };
    },
    [showModal]
  );
  useEffect(updateDimensions, [showModal, pages, headerRef]);
  useResize(updateDimensions);

  const dismissModalIfBackgroundClicked = () => {
    if (showModal) {
      onModalToggle(false);
    }
  };

  const title = pages.find(({ id }) => activePageId === id)?.title ?? "";

  return (
    <div {...props} onClick={dismissModalIfBackgroundClicked}>
      <div ref={setHeaderRef}>
        <HamburgerButton
          title={HAMBURGER_BUTTON_TITLE}
          active={showModal}
          onClick={(e) => {
            onModalToggle(!showModal);
            e.currentTarget.blur();
          }}
        />
        <h2>{title}</h2>
      </div>
      <nav
        ref={navContainerRef}
        style={{
          top: headerHeight,
          width: headerWidth,
          height: showModal ? maxHeight : 0,
        }}
      >
        {pages.map(({ id, title }) => (
          <a
            key={id}
            href={id}
            onClick={(e) => {
              onPageClicked(e, id);
              onModalToggle(false);
            }}
          >
            {title}
          </a>
        ))}
      </nav>
    </div>
  );
};

const NavigationHamburger = styled(BaseNavigationHamburger)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0.5em 0.5em;
  margin: auto;

  &:before {
    content: "";
    height: 200%;
    transition: background-color 250ms;
    background-color: transparent;
  }

  & > * {
    transition: height 250ms, background-color 2500ms;
    background-color: initial;
  }

  & > :first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px 8px 0 0;
  }

  & > nav:last-child {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    border-radius: 0 0 8px 8px;
  }

  ${({ showModal, theme }) =>
    showModal &&
    css`
      & > * {
        transition: height 250ms;
        background-color: ${theme.colors.LIGHT_BACKGROUND};
        z-index: 11;
      }

      &:before {
        background-color: ${theme.colors.DISABLED_BACKGROUND};
        opacity: 0.8;
        position: fixed;
        display: block;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 10;
      }
    `}
  h2 {
    background: ${({ theme }) => theme.gradients.YELLOW_TO_GREEN_TO_BLUE};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0.5em 0 0.5em 0.3em;
  }

  a {
    padding-left: 3em;
    line-height: 250%;
    color: ${({ theme }) => theme.colors.BLACK};
    font-size: ${({ theme }) => `${theme.fonts.sizes.h5}rem`};
    transition: font-size 250ms;
    cursor: pointer;
    text-decoration: none;
  }
`;

export default NavigationHamburger;
