import { lazy, Suspense } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { SWRConfig } from 'swr';
import {
  isSessionStorageAvailable,
  PersonalityTestProviderWithSessionStorage,
  PersonalityTestProviderWithoutSessionStorage,
} from './contexts/PersonalityTestContext';
import {
  ROUTE_SHORT_STANDARD,
  ROUTE_SHORT_COACH,
  ROUTE_SHORT_ENTREPRENEUR,
  ROUTE_PRO_STANDARD,
  ROUTE_PRO_COACH,
  ROUTE_RESULT,
} from 'typings/routes';
import { ThemeProvider, LoadingIndicator } from 'utils/harmony-ui';
import PageContainer from './pages/common/PageContainer';
import globalSwrConfig from 'utils/globalSwrConfig';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlag } from 'typings/constants';
import 'utils/harmony-ui/dist/styles.css';
import { PersonalityTestContext } from 'typings/PersonalityTest';


const ShortTest = lazy(() => import('./routers/ShortTestRouter'));
const ProTest = lazy(() => import('./routers/ProTestRouter'));
const CoachTest = lazy(() => import('./routers/CoachTestRouter'));
const EntrepreneurTest = lazy(() => import('./routers/EntrepreneurTestRouter'));
const ResultPage = lazy(() => import('pages/ResultPage'));

const App: React.FunctionComponent<{}> = () => {
  const enableCoachTest = useFeatureFlag(FeatureFlag.enableCoachTest);
  const enableEntrepreneurTest = useFeatureFlag(FeatureFlag.enableEntrepreneurTest);

  const PersonalityTestProvider = isSessionStorageAvailable()
    ? PersonalityTestProviderWithSessionStorage
    : PersonalityTestProviderWithoutSessionStorage;

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <BrowserRouter>
        <ThemeProvider>
          <SWRConfig value={globalSwrConfig}>
            <PersonalityTestProvider>
              <PageContainer>
                <Switch>
                  {/** Result route needs to be first in line */}
                  <Route path={ROUTE_RESULT} component={ResultPage} />
                  <Route path={ROUTE_SHORT_STANDARD} component={ShortTest} />
                  {enableCoachTest && (
                    <Route path={ROUTE_SHORT_COACH} component={CoachTest} />
                  )}
                  {enableEntrepreneurTest && (
                      <Route path={ROUTE_SHORT_ENTREPRENEUR} component={EntrepreneurTest} />
                  )}
                  <Route path={ROUTE_PRO_STANDARD} render={(props) => <ProTest {...props} context={PersonalityTestContext.standard} />} />
                  <Route path={ROUTE_PRO_COACH} render={(props) => <ProTest {...props} context={PersonalityTestContext.coach} />} />
                  <Redirect to={ROUTE_SHORT_STANDARD} />
                </Switch>
              </PageContainer>
            </PersonalityTestProvider>
          </SWRConfig>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
