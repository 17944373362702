import {
  forwardRef,
  useRef,
  useState,
  useLayoutEffect,
  PropsWithChildren,
  useEffect,
} from 'react';
import mergeRefs from 'react-merge-refs';
import styled from 'styled-components';
import useResize from '../hooks/useResize';
import {useTestRouteParams} from "../../../contexts/testRouter";
import {PersonalityTestType} from "../../../typings/PersonalityTest";

export type StickyContainerProps = {
  /** If true the sticky container is stuck at the bottom of the page */
  bottom?: boolean;
  /** Distance from the top/bottom of the page to place the container */
  padding?: number;
  /** Forwarded to the sticky container */
  className?: string;
  /** Forwarded to the sticky container */
  style?: React.CSSProperties;
  /** Forwarded to the sticky container */
  onClick?: () => void;
};

const Container = styled.div<StickyContainerProps>`
  position: sticky;
  width: 100%;
  margin: auto;
  z-index: 10;
`;

export const stickyContainerId = 'sticky-container';

export const BaseStickyContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<StickyContainerProps>
>(({ children, bottom, style, className, padding = 0 }, ref) => {
  const container = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const {type} = useTestRouteParams();

  const updateHeight = () => {
    const newHeight = container.current?.offsetHeight ?? 0;
    if (newHeight !== height) {
      setHeight(newHeight);
    }
  };

  // recalculate after a few ms in case the fonts changed.
  const updateHeightDelayed = () => {
    setTimeout(updateHeight, 100);
  };

  useLayoutEffect(updateHeight);
  useResize(updateHeight);
  useEffect(updateHeightDelayed);

  let mergedStyles = {
    ...style,
    top: bottom ? 'auto' : (type === PersonalityTestType.shortEntrepreneur ? 65 : padding),
    bottom: bottom ? padding : 'auto',
    marginBottom: type === PersonalityTestType.shortEntrepreneur ? 20 : 0
  };

  return (
    <>
      <Container
        data-testid={stickyContainerId}
        ref={mergeRefs([container, ref])}
        style={mergedStyles}
        className={className}>
        {children}
      </Container>
    </>
  );
});

const StickyContainer = styled(BaseStickyContainer)``;

export default StickyContainer;
