import { FunctionComponent } from 'react';
import {
  ToastContainer as OriginalToastContainer,
  toast as originalToat,
  ToastOptions,
} from 'react-toastify';
import styled from 'styled-components';
import Icon from './Icon';
import Typography from './Typography';

import 'react-toastify/dist/ReactToastify.css';
import { ThemeIcon } from '../theme/icons';

type NotificationProps = {
  children?: any;
  className?: string;
  icon: ThemeIcon;
};

const Notification: FunctionComponent<NotificationProps> = ({
  children,
  icon,
  className,
}) => {
  return (
    <div className={className}>
      <Icon icon={icon} size="medium" color="white" />
      <Typography>{children}</Typography>
    </div>
  );
};

const StyledNotification = styled(Notification)`
  display: flex;
  flex-wrap: none;
  padding: 1rem;

  ${Icon} {
    margin-right: 1rem;
  }

  ${Typography} {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

const StyledCancelIcon = styled(Icon)`
  margin-top: 1rem;
  margin-right: 1rem;
  flex: none;
`;

const ToastContainer = styled(OriginalToastContainer).attrs(({ theme }) => ({
  closeButton: () => (
    <StyledCancelIcon size="tiny" icon="IconCancel" color="white" />
  ),
}))`
  &.Toastify__toast-container--top-left,
  &.Toastify__toast-container--top-center,
  &.Toastify__toast-container--top-right {
    top: ${({ theme }) => theme.sizes.headerHeight + 10}px;
  }

  .Toastify__progress-bar {
    /**
     * The mechanism to remove messages is inexplicably linked
     * to the dom. The progress bar needs to be "visible" for it
     * to work.
     */
    height: 0;
  }

  .Toastify__toast {
    box-shadow: 0px 24px 24px rgba(76, 73, 71, 0.15);
    border-radius: 16px;
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.SUCCESS};
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.DANGER};
  }

  .Toastify__toast--info {
    background-color: ${({ theme }) => theme.colors.PRIMARY};
  }

  @media only screen and (max-width: 480px) {
    padding: 1rem;
    box-sizing: border-box;

    & > * {
      margin-bottom: 1rem;
    }
  }
`;

const toast = {
  success: (content: string, options?: ToastOptions): React.ReactText =>
    originalToat.success(
      <StyledNotification icon="IconCheckCircle">{content}</StyledNotification>,
      options,
    ),
  error: (content: string, options?: ToastOptions): React.ReactText =>
    originalToat.error(
      <StyledNotification icon="IconError">{content}</StyledNotification>,
      options,
    ),
  info: (content: string, options?: ToastOptions): React.ReactText =>
    originalToat.info(
      <StyledNotification icon="IconQuestion">{content}</StyledNotification>,
      options,
    ),
};

export { ToastContainer, toast };
