import { forwardRef } from "react";
import styled from "styled-components";
import Button from "./Button";

export type NavigationBottomProps = {
  forwardButtonText?: string;
  showForwardButton?: boolean;
  backButtonText?: string;
  showBackButton?: boolean;
  onBackButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onForwardButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  "data-testid"?: string;
};

export const BaseNavigationBottom = forwardRef<
  HTMLDivElement,
  NavigationBottomProps
>(
  (
    {
      forwardButtonText,
      backButtonText,
      showForwardButton = false,
      showBackButton = false,
      onBackButtonClick = () => {},
      onForwardButtonClick = () => {},
      ...props
    },
    ref
  ) => (
    <div {...props} ref={ref}>
      {showBackButton && (
        <Button
          size="small"
          mode="outlined"
          icon="IconChevronLeft"
          iconPosition="left"
          iconAlt={"back"}
          children={backButtonText ? backButtonText : undefined}
          onClick={onBackButtonClick}
        />
      )}
      {showForwardButton && (
        <Button
          size="small"
          mode="outlined"
          icon="IconChevronRight"
          iconAlt={"forward"}
          children={forwardButtonText ? forwardButtonText : undefined}
          onClick={onForwardButtonClick}
        />
      )}
    </div>
  )
);

const NavigationBottom = styled(BaseNavigationBottom)`
  display: flex;
  padding: 1.5em 0em 1em 0em;
  margin: auto;
  width: 100%;
  justify-content: space-between;

  @media (min-width: 520px) {
    justify-content: center;
  }

  & > ${Button}:first-child {
    margin: 0 0.75em 0 0;
  }

  & > ${Button}:last-child {
    margin: 0 0 0 0.75em;
  }

  & > ${Button}:only-child {
    margin: 0 auto;
  }
`;

export default NavigationBottom;
