import { createContext, useContext, useReducer } from 'react';
import { PersonalityTestState } from 'typings/PersonalityTest';
import { PersonalityTestAction } from 'typings/Actions';
import { SESSION_STATE_NAME } from 'typings/constants';
import personalityTestReducer, {
  getInitialState,
} from 'reducers/personalityTestReducer';
import { useStorageReducer } from 'react-storage-hooks';

type PersonalityTestContextType = PersonalityTestState & {
  dispatch: React.Dispatch<PersonalityTestAction>;
};

const PersonalityTestContext = createContext<PersonalityTestContextType>(
  {} as PersonalityTestContextType,
);

export const isSessionStorageAvailable = (): boolean => {
  try {
    sessionStorage.getItem(SESSION_STATE_NAME);
    return true;
  } catch {
    return false;
  }
};

export const PersonalityTestProviderWithSessionStorage: React.FunctionComponent<{
  initialState?: PersonalityTestState;
}> = ({ initialState = getInitialState(), children }) => {
  const [state, dispatch] = useStorageReducer(
    window.sessionStorage,
    SESSION_STATE_NAME,
    personalityTestReducer,
    initialState,
  );
  return (
    <PersonalityTestContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PersonalityTestContext.Provider>
  );
};

export const PersonalityTestProviderWithoutSessionStorage: React.FunctionComponent<{
  initialState?: PersonalityTestState;
}> = ({ initialState = getInitialState(), children }) => {
  const [state, dispatch] = useReducer(personalityTestReducer, initialState);
  return (
    <PersonalityTestContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PersonalityTestContext.Provider>
  );
};

export const usePersonalityTestContext = (): PersonalityTestContextType =>
  useContext<PersonalityTestContextType>(PersonalityTestContext);
