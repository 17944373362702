import { Children, useEffect, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import {
  CarouselProvider,
  DotGroup,
  Slide,
  SlideProps,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import useResize from '../hooks/useResize';
import useRefCallback from '../hooks/useRefCallback';

const StyledDotGroup = styled(DotGroup)`
  margin: 5px auto;
  display: flex;
  justify-content: center;

  & > button {
    background: ${({ theme }) => theme.colors.PRIMARY};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    transition: opacity 0.25s ease-in;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
    padding: 0;
    border: 0;
    opacity: 0.25;

    :focus {
      outline: none;
    }
  }

  /**
   * the pure-carousel will mark as "selected" all visible slides (including
   * partially visible slides). This css "hack" first highlights all "selected"
   * dots, and them dims all "selected" who follow another "selected" dot
   */
  & > button.carousel__dot--selected {
    opacity: 1;
  }
  & > button.carousel__dot--selected ~ button.carousel__dot--selected {
    opacity: 0.25;
  }
`;

const DisabledCarousel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    flex: 1;
  }
`;

export type CarouselProps = {
  id?: string;
  disabled?: boolean;
  'data-testid'?: string;
  children?: any;
  className?: string;
  style?: CSSProperties;
};

const areValidCarouselChildren = (
  children: unknown,
): children is Array<React.ReactElement> =>
  !(children as Array<React.ReactElement>).find(({ type }) => type !== Slide);

const DATA_TEST_ID = 'data-testid';

const Carousel: React.FunctionComponent<CarouselProps> = ({
  id,
  disabled,
  [DATA_TEST_ID]: dataTestid,
  children,
  className,
  style,
}) => {
  const [containerRef, setContainerRef] = useRefCallback<HTMLElement>();
  const [naturalSlideWidth, setNaturalSlideWidth] = useState(560);
  const [visibleSlides, setVisibleSlides] = useState(1);
  const childrenAsArray = Children.toArray(children);

  const updateCarouselWidth = (container?: HTMLElement | null) => {
    if (container) {
      const containerWidth = container.clientWidth;
      const sliderWidth = container.clientWidth - 50;
      setNaturalSlideWidth(sliderWidth);
      setVisibleSlides(containerWidth / sliderWidth);
    }
  };

  if (!areValidCarouselChildren(childrenAsArray)) {
    throw new Error(
      'Carousel Children should be Slides containing an unique `index` prop',
    );
  }

  useEffect(() => {
    if (id) {
      containerRef?.setAttribute('id', id);
    } else {
      containerRef?.removeAttribute('id');
    }
  }, [containerRef, id]);

  useEffect(() => {
    if (dataTestid) {
      containerRef?.setAttribute(DATA_TEST_ID, dataTestid);
    } else {
      containerRef?.removeAttribute(DATA_TEST_ID);
    }
  }, [containerRef, dataTestid]);

  useEffect(() => updateCarouselWidth(containerRef), [containerRef]);
  useResize(() => updateCarouselWidth(containerRef));

  return disabled ? (
    <DisabledCarousel
      data-testid={dataTestid}
      id={id}
      className={className}
      style={style}>
      {childrenAsArray.map(({ props }) => props.children)}
    </DisabledCarousel>
  ) : (
    <CarouselProvider
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={10}
      totalSlides={childrenAsArray.length}
      visibleSlides={visibleSlides}
      isIntrinsicHeight={true}>
      <Slider className={className} style={style}>
        {childrenAsArray.map(({ props, ...child }, index) => ({
          ...child,
          props: {
            index,
            ...props,
          },
        }))}
      </Slider>
      <StyledDotGroup disableActiveDots={false} />
      <span ref={(p) => p?.parentElement && setContainerRef(p.parentElement)} />
    </CarouselProvider>
  );
};

export default Carousel;

export { Carousel, Slide };
export type { SlideProps };
