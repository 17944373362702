import { useRouteMatch } from 'react-router-dom';
import {
  PersonalityTestSection,
  PersonalityTestType,
} from 'typings/PersonalityTest';
import { ROUTE_QUESTIONNAIRE, ROUTE_TEST } from 'typings/routes';

export type TestRouteParams = {
  type?: PersonalityTestType;
  section?: PersonalityTestSection;
  questionIndex?: string;
};

const useTestRouteParams = (): TestRouteParams => {
  const matchForType = useRouteMatch<TestRouteParams>(ROUTE_TEST);
  const matchForPartAndQuestion = useRouteMatch<TestRouteParams>(
    ROUTE_QUESTIONNAIRE,
  );

  return {
    ...matchForPartAndQuestion?.params,
    ...matchForType?.params,
  };
};

export default useTestRouteParams;
