import { createGlobalStyle } from 'styled-components';
import theme from './theme';

import './fonts/fonts.css';

const GlobalStyles = createGlobalStyle`
  * {
    font-family: "${theme.fonts.family.regular}", Arial;
    font-weight: normal;
    font-style: normal;
    color: ${theme.colors.TEXT};
  }

  body {
    margin: 0;
    background-color: ${theme.colors.LIGHT_BACKGROUND};
  }

  html, body, #root {
    width: 100%;
    height: 100%;
    font-size: ${theme.fonts.root.sm}px;

    @media only screen and (min-width: ${theme.breakpoints.md.width}px) {
      font-size: ${theme.fonts.root.md}px;
    }

    @media only screen and (min-width: ${theme.breakpoints.lg.width}px) {
      font-size: ${theme.fonts.root.lg}px;
    }
  }
`;

export default GlobalStyles;
