import { PersonalityTestAction, ActionType } from 'typings/Actions';
import { PersonalityTestState } from 'typings/PersonalityTest';
import questionsReducer from './questionsReducer';
import { SESSION_VISITOR_ID_KEY } from 'typings/constants';

export const getInitialState = (): PersonalityTestState => ({
  questions: {
    mostApplicable: [],
    leastApplicable: [],
  },
  type: undefined,
});

const personalityTestReducer = (
  state: PersonalityTestState,
  action: PersonalityTestAction,
): PersonalityTestState => {
  switch (action.type) {
    case ActionType.SetQuestions:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SetParticipant:
      return { ...state, participant: action.payload };
    case ActionType.UpdateAnswer:
    case ActionType.ResetTest:
      return {
        ...state,
        questions: {
          mostApplicable: questionsReducer(
            state.questions.mostApplicable,
            action,
          ),
          leastApplicable: questionsReducer(
            state.questions.leastApplicable,
            action,
          ),
        },
      };
    case ActionType.SetVisitorId:
      return {
        ...state,
        [SESSION_VISITOR_ID_KEY]: action.payload,
      };
    default:
      return state;
  }
};

export default personalityTestReducer;
