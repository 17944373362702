import 'react-app-polyfill/stable';
import './init/internationalization';
import './init/mockServiceWorker';
import './init/sentry';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import TrackingService from './services/TrackingService';

smoothscroll.polyfill();
TrackingService.initialize();

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
