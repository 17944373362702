import { useCallback, useState } from 'react';
import useComponentUnmounted from './useComponentUnmounted';

/**
 * useRefCallback that returns a stateful reference.
 * a `mainRef` value is only returned when the target element is
 * garanteed to have been mounted to the DOM.
 *
 */
const useRefCallback = <T extends HTMLElement>(): [
  ref: T | undefined,
  setRef: (node: T | null) => void,
] => {
  const isUnmounted = useComponentUnmounted();
  const [ref, setRef] = useState<T>();
  const setRefCallback = useCallback(
    (node: T | null, tries = 10): void => {
      if (node === null || !(node.offsetHeight || node.offsetWidth) || !tries) {
        setTimeout(() => setRefCallback(node, tries - 1), 20);
      } else if (!isUnmounted()) {
        setRef(node);
      }
    },
    [isUnmounted],
  );
  return [ref, setRefCallback];
};

export default useRefCallback;
