import { debounce } from 'debounce';
import { useEffect, useState } from 'react';
import useComponentUnmounted from './useComponentUnmounted';

export type WindowDimensions = {
  height: number;
  width: number;
};

/**
 * @param callback called when a resize event is detected by the `window`
 */
const useResize = (
  callback: (e: Event) => void = () => {},
): WindowDimensions => {
  const isUnmounted = useComponentUnmounted();
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const onResizeCallback = (e: Event) => {
      if (!isUnmounted()) {
        setWindowDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }
      callback(e);
    };
    const debouncedCallback = debounce(onResizeCallback, 25);
    window.addEventListener('resize', debouncedCallback);
    return () => window.removeEventListener('resize', debouncedCallback);
  }, [callback, isUnmounted]);
  return windowDimensions;
};

export default useResize;
