import { usePersonalityTestContext } from 'contexts/PersonalityTestContext';
import {
  Question,
  Participant,
  PersonalityTestType,
  Questions,
} from 'typings/PersonalityTest';
import { ActionType } from 'typings/Actions';
import { useCallback } from 'react';

export interface PersonalityTestActions {
  setQuestions: (questions: Questions, type: PersonalityTestType) => void;
  addAnswer: (question: Question, index: number) => void;
  setParticipant: (participant: Participant) => void;
  setVisitorId: (visitorId: string) => void;
  resetTest: () => void;
}

/**
 * Consumes data from the `PersonalityTestContext`.
 */
const usePersonalityTestActions = (): PersonalityTestActions => {
  const { dispatch } = usePersonalityTestContext();

  const setQuestions = useCallback(
    (questions: Questions, type: PersonalityTestType) => {
      dispatch({
        type: ActionType.SetQuestions,
        payload: { questions, type: type },
      });
    },
    [dispatch],
  );

  const addAnswer = useCallback(
    (question: Question, answerIndex: number) => {
      dispatch({
        type: ActionType.UpdateAnswer,
        payload: {
          answerIndex,
          questionId: question.questionId,
        },
      });
    },
    [dispatch],
  );

  const setParticipant = useCallback(
    (participant: Participant): void => {
      dispatch({
        type: ActionType.SetParticipant,
        payload: participant,
      });
    },
    [dispatch],
  );

  const setVisitorId = useCallback(
    (visitorId: string): void => {
      dispatch({
        type: ActionType.SetVisitorId,
        payload: visitorId,
      });
    },
    [dispatch],
  );

  const resetTest = useCallback(() => {
    dispatch({ type: ActionType.ResetTest });
    dispatch({ type: ActionType.SetParticipant });
  }, [dispatch]);

  return {
    setQuestions,
    addAnswer,
    setParticipant,
    setVisitorId,
    resetTest,
  };
};

export default usePersonalityTestActions;
