import { useContext } from 'react';
import { PersonalityTestSection } from 'typings/PersonalityTest';
import { TestRouteContext } from './TestRoute';

export type TestRouteActions = {
  goToNextPage: () => void;
  goToFirstPage: () => void;
  // TODO this does not belongs here, since the TestRouter doesn't have a clue about questions.
  goToQuestionnaireQuestion: (
    section: PersonalityTestSection,
    index: number,
  ) => void;
};

const useTestRouteActions = (): TestRouteActions => {
  return useContext(TestRouteContext);
};

export default useTestRouteActions;
