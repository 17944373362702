import styled from 'styled-components';
import Button from './Button';
import { forwardRef } from 'react';

export interface ErrorFallbackDefaultProps {
  title: string;
  subtitle: string;
  buttonText: string;
  onClick: () => void;
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  padding: 0 24px;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const Title = styled.h2`
  font-size: 240px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.PRIMARY};
  background: -webkit-${({ theme }) => theme.gradients.YELLOW_TO_GREEN_TO_BLUE};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 72px;
    margin: 0 0 16px;
  }
`;

const WarningSubtitle = styled.h3`
  color: ${({ theme }) => theme.colors.PRIMARY};
  font-size: 34px;
  font-style: ${({ theme }) => theme.fonts.family.semiBold};
  font-weight: bold;
  margin: 0 0 24px;
  max-width: 600px;
  text-align: center;
`;

const ErrorFallbackDefault = styled(
  forwardRef<HTMLDivElement, ErrorFallbackDefaultProps>(
    ({ title, subtitle, buttonText, onClick, ...props }, ref) => {
      return (
        <Container {...props} ref={ref}>
          <Title>{title}</Title>
          <WarningSubtitle>{subtitle}</WarningSubtitle>
          <Button mode="outlined" onClick={onClick}>
            {buttonText}
          </Button>
        </Container>
      );
    },
  ),
)``;

export default ErrorFallbackDefault;
