import styled, { css } from 'styled-components';

type valuesType = [
  { name: string; value: string },
  { name: string; value: string },
];

export type SelectorProps = {
  values: valuesType;
  errorMessage?: string | null;
  onChange?: (event: React.FormEvent<HTMLButtonElement>) => void;
  selected?: string;
  tabIndex?: number;
  name?: string;
  id?: string;
};

type SelectorOptionProps = {
  selected: boolean;
};

const SelectedHighlight = css<SelectorOptionProps>`
  background-color: ${(props) => props.theme.colors.WHITE};
  border-radius: 8px;
  box-shadow: 0px 24px 24px rgba(76, 73, 71, 0.15);
  color: ${({ theme, selected }) =>
    selected ? theme.colors.BLUE : theme.colors.DARK_GRAY};
  opacity: 1;
`;

const SelectorOption = styled.button<SelectorOptionProps>`
  user-select: none;
  appearance: none;
  align-items: center;
  border: none;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.DARK_GRAY};
  background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: ${(props) => props.theme.fonts.family.regular};
  font-size: ${(props) => `${props.theme.fonts.sizes.body}rem`};
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 19px;
  opacity: 0.5;
  text-transform: uppercase;
  ${({ selected }) => selected && SelectedHighlight}
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.RED};
  font-size: ${(props) => `${props.theme.fonts.sizes.small}rem`};
  letter-spacing: 0.1em;
  line-height: 15px;
  margin-top: 4px;
  text-align: center;
`;

const Container = styled.div<Pick<SelectorProps, 'errorMessage' | 'selected'>>`
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  font-size: ${(props) => `${props.theme.fonts.sizes.body}rem`};
  height: 48px;
  line-height: 21px;
  padding: 4px;
  width: 100%;

  ${({ errorMessage, theme }) =>
    errorMessage &&
    css`
      border: 1px solid ${theme.colors.RED};
    `}
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.LIGHT_GRAY};
    `}
`;

const Selector: React.FunctionComponent<SelectorProps> = ({
  errorMessage,
  onChange,
  selected,
  values,
  tabIndex,
  name: FieldName,
  id,
}) => (
  <div data-test-id={id}>
    <Container selected={selected} errorMessage={errorMessage}>
      {values.map(({ value, name }) => (
        <SelectorOption
          type="button"
          key={value}
          name={FieldName}
          onClick={(e) => {
            if (onChange && selected !== value) {
              onChange(e);
              e.currentTarget.blur();
            }
          }}
          value={value}
          tabIndex={tabIndex}
          selected={value === selected}>
          {name}
        </SelectorOption>
      ))}
    </Container>
    {errorMessage && errorMessage !== '' && (
      <ErrorMessage data-testid="selector-error-message">
        {errorMessage}
      </ErrorMessage>
    )}
  </div>
);

export default Selector;
