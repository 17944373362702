export interface GreatorTheme {
  colors: {
    TEXT: string;
    PRIMARY: string;
    SECONDARY: string;
    DANGER: string;
    WARNING: string;
    SUCCESS: string;
    MEDIUM_BACKGROUND: string;
    LIGHT_BACKGROUND: string;
    TRANSPARENT: string;
    DARKENED_BACKGROUND: string;
    DISABLED_BACKGROUND: string;
    WHITE: string;
    BLACK: string;

    /** @deprecated use `PRIMARY` instead */
    BLUE: string;
    /** @deprecated use `SECONDARY` instead */
    MAGENTA: string;
    /** @deprecated use `DANGER` instead */
    RED: string;
    /** @deprecated use `SUCCESS` instead */
    GREEN: string;
    /** @deprecated use `WARNING` instead */
    YELLOW: string;
    /** @deprecated currently no alternative :( */
    LIGHT_GRAY: string;
    /** @deprecated use `DISABLED_BACKGROUND` or `TEXT` */
    DARK_GRAY: string;
  };
  gradients: {
    YELLOW_TO_GREEN_TO_BLUE: string;
    YELLOW_TO_GREEN_TO_BLUE_SOFT: string;
    YELLOW_TO_GREEN_TO_BLUE_SOFT_OPAQUE: string;
    DARK_BLUE_TO_LIGHT_BLUE: string;
  };
  fonts: {
    family: {
      medium: string;
      regular: string;
      semiBold: string;
    };
    sizes: {
      small: number;
      body: number;
      h1: number;
      h2: number;
      h3: number;
      h4: number;
      h5: number;
      h6: number;
      caption: number;
      /** @deprecated use `body` instead */
      medium: number;
      /** @deprecated use `h5` instead */
      large: number;
    };
    height: {
      body: string;
      heading: string;
    };
    root: {
      sm: number;
      md: number;
      lg: number;
    };
  };
  gutters: {
    small: number;
    medium: number;
    large: number;
  };
  spacing: {
    [key: string]: number;
  };
  breakpoints: {
    [key: string]: {
      columns: number;
      width: number;
    };
  };
  breakpointList: string[];
  sizes: {
    headerHeight: number;
    headerHeightEntrepreneur: number;
    maxTextWidth: number;
  };
}

const themeVariables: GreatorTheme = {
  colors: {
    TEXT: '#4c4947',
    PRIMARY: '#2565cb',
    SECONDARY: '#FF007D',
    DANGER: '#BF1975',
    WARNING: '#fffc93',
    SUCCESS: '#48A3A4',
    MEDIUM_BACKGROUND: '#c4c4c4',
    LIGHT_BACKGROUND: '#fafafa',
    TRANSPARENT: 'transparent',
    DARKENED_BACKGROUND: '#000077',
    DISABLED_BACKGROUND: '#4c4947',
    WHITE: '#fdfdfd',
    BLACK: '#333333',

    BLUE: '#2565cb',
    MAGENTA: '#FF007D',
    RED: '#eb5757',
    GREEN: '#48a3a4',
    YELLOW: '#fffc93',
    LIGHT_GRAY: '#e8edf0',
    DARK_GRAY: '#4c4947',
  },
  gradients: {
    YELLOW_TO_GREEN_TO_BLUE:
      'linear-gradient(15.5deg,#fffd82 -25.89%,#00a5a5 37.05%,#0064d2 100%)',
    YELLOW_TO_GREEN_TO_BLUE_SOFT:
      'linear-gradient(31.51deg, #65BB85 -20.48%, #00A5A5 29.22%, #0064D2 100%)',
    YELLOW_TO_GREEN_TO_BLUE_SOFT_OPAQUE:
      'linear-gradient(45deg, rgba(255, 253, 130, 0.5) 0%, rgba(0, 165, 165, 0.5) 50.21%, rgba(0, 100, 210, 0.5) 100%)',
    DARK_BLUE_TO_LIGHT_BLUE:
        'linear-gradient(45deg,#4276f8,#64bbff)',
  },
  fonts: {
    family: {
      medium: 'GoldplayMedium',
      regular: 'GoldplayRegular',
      semiBold: 'GoldplaySemiBold',
    },
    sizes: {
      h1: 2.25,
      h2: 2,
      h3: 1.75,
      h4: 1.5,
      h5: 1.25,
      h6: 1.125,

      caption: 1.125,

      body: 1,
      small: 0.75,

      /** @deprecated use `body` instead */
      medium: 1,
      /** @deprecated use `h5` instead */
      large: 1.25,
    },
    height: {
      body: '150%',
      heading: '120%',
    },
    root: {
      sm: 14,
      md: 16,
      lg: 18,
    },
  },
  spacing: {
    xs: 0.25,
    sm: 0.5,
    md: 1,
    lg: 1.5,
    xl: 2,
    xxl: 3,
    xxxl: 4.5,
  },
  breakpoints: {
    xs: {
      columns: 12,
      width: 0,
    },
    sm: {
      columns: 12,
      width: 320,
    },
    md: {
      columns: 12,
      width: 768,
    },
    lg: {
      columns: 12,
      width: 1056,
    },
    xl: {
      columns: 12,
      width: 1312,
    },
  },
  breakpointList: ['xs', 'sm', 'md', 'lg', 'xl'],
  gutters: {
    small: 0.25,
    medium: 1,
    large: 1.75,
  },
  sizes: {
    headerHeight: 80,
    headerHeightEntrepreneur: 65,
    maxTextWidth: 700,
  },
};

export default themeVariables;
