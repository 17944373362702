import { memo, Fragment } from 'react';
import styled from 'styled-components';
import { Quadrant, REFERENCE_BACKGROUND_SIZE } from './types';

export type ButterflyBackgroundProps = {
  startRadius: number;
  endRadius: number;
  /** Determins in which quadrant to put the reference numbers (if any) */
  quadrant?: Quadrant;
  className?: string;
};

const fillColor = '#E7EDF1';
const borderColor = '#4D4947';

const StyledText = styled.text`
  font-size: ${({ theme }) => `${theme.fonts.sizes.small}rem`};
  color: ${borderColor};
`;

const ButterflyBackground = memo<ButterflyBackgroundProps>(
  ({ startRadius, endRadius, quadrant, className }) => {
    const radius = ((startRadius + endRadius) / 2) * REFERENCE_BACKGROUND_SIZE;
    const width = Math.abs(startRadius - endRadius) * REFERENCE_BACKGROUND_SIZE;
    const outerRadius = 100 * REFERENCE_BACKGROUND_SIZE;

    return (
      <g className={className}>
        <circle
          stroke={fillColor}
          strokeWidth={2}
          r={2}
          fill={fillColor}
          opacity={1}
          cy={0}
          cx={0}
        />
        <circle
          stroke={fillColor}
          strokeWidth={width}
          r={radius}
          fill="none"
          opacity={1}
          cy={0}
          cx={0}
        />
        <circle
          stroke={borderColor}
          strokeWidth={1}
          r={outerRadius}
          fill="none"
          opacity={0.15}
          cy={0}
          cx={0}
        />
        {[
          [startRadius, radius - width / 2],
          [endRadius, radius + width / 2],
        ].map(([t, r]) => {
          const angle =
            typeof quadrant !== 'undefined' ? -45 + 90 * quadrant : 0;
          const spacing = typeof quadrant !== 'undefined' ? 500 / r : 0.005;
          return (
            <Fragment key={t}>
              <path
                d={`M ${r * Math.cos((Math.PI / 180) * (angle - spacing))} 
                    ${r * Math.sin((Math.PI / 180) * (angle - spacing))} 
                  A ${r} 
                    ${r} 
                    0 1 0 
                    ${r * Math.cos((Math.PI / 180) * (angle + spacing))} 
                    ${r * Math.sin((Math.PI / 180) * (angle + spacing))} 
              `}
                stroke={borderColor}
                rotate={45}
                strokeWidth={1}
                r={r}
                fill="none"
                opacity={0.15}
                cy={0}
                cx={0}
              />
              {typeof quadrant !== 'undefined' && (
                <StyledText
                  x={r * Math.cos((Math.PI / 180) * angle)}
                  y={r * Math.sin((Math.PI / 180) * angle)}
                  opacity={0.35}
                  dominantBaseline="middle"
                  textAnchor="middle">
                  {t}
                </StyledText>
              )}
            </Fragment>
          );
        })}
      </g>
    );
  },
);

export default ButterflyBackground;
