import styled, { css, useTheme } from 'styled-components';
import Color from 'color';
import { forwardRef } from 'react';
import Icon from './Icon';

import type { ThemeIcon } from '../theme/icons';

export type ButtonProps = {
  disabled?: boolean;
  fill?: boolean;
  icon?: ThemeIcon;
  iconAlt?: string;
  iconPosition?: 'left' | 'right';
  size?: 'large' | 'medium' | 'small';
  mode?: 'outlined' | 'contained' | 'plain';
  type?: 'button' | 'submit' | 'reset';
  color?: 'primary' | 'secondary';
  style?: React.CSSProperties;
  className?: string;
  tabIndex?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  'data-testid'?: string;
  'aria-label'?: string;
};

type StyledBaseButtonProps = {
  backgroundColor: string;
  mainColor: string;
  focusColor: string;
  fontSize: string;
  iconOnly: boolean;
  _fill: boolean;
  border: boolean;
  focusOnText: boolean;
};

type ValidThemeColor = 'PRIMARY' | 'SECONDARY';

const StyledBaseButton = styled.button<StyledBaseButtonProps>`
  background-position: center;
  transition: background-color 0.8s;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  appearance: none;
  padding: 0 0 0 0;
  border-radius: 100px;
  border-width: 1px;
  border-style: ${({ border }) => (border ? 'solid' : 'none')};
  border-color: ${({ mainColor }) => mainColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ fontSize }) => fontSize};
  padding: 0.6em 1em;
  outline: 0;
  height: max-content;
  width: ${({ _fill }) => (_fill ? '100%' : 'initial')};
  display: ${({ _fill }) => (_fill ? 'flex' : 'inline-flex')};
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  text-align: center;

  ${({ iconOnly }) =>
    iconOnly &&
    css`
      padding: 0.5rem 0.5rem;
    `}

  &:focus {
    outline: 0;
  }

  ${({ focusOnText, focusColor }) =>
    !focusOnText &&
    css`
      &:focus,
      &:hover {
        background-color: ${focusColor};
      }

      @media (pointer: none), (pointer: coarse) {
        &:hover {
          background-color: initial;
        }
      }
    `}

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  & > span {
    display: inline-flex;
    align-items: center;
    transition: color 0.8s;
    color: ${({ mainColor }) => mainColor};
    font-family: ${({ theme }) => theme.fonts.family.semiBold};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1.25em;
    white-space: nowrap;
    outline: 0;
    ${({ focusOnText, focusColor }) =>
      focusOnText &&
      css`
        &:focus,
        &:hover {
          color: ${focusColor};
        }

        @media (pointer: none), (pointer: coarse) {
          &:hover {
            color: initial;
          }
        }
      `}
  }

  & > ${Icon} {
    outline: 0;
    margin: auto;
    height: ${({ fontSize }) => fontSize};
    width: ${({ fontSize }) => fontSize};
    stroke: ${({ mainColor }) => mainColor};

    &:last-child {
      padding-left: ${({ iconOnly }) => (iconOnly ? 0 : '0.5em')};
    }

    &:first-child {
      padding-right: ${({ iconOnly }) => (iconOnly ? 0 : '0.5em')};
    }

    &:disabled {
      stroke-opacity: 0.3;
    }
  }
`;

const Button = styled(
  forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    (
      {
        disabled = false,
        fill = false,
        size = 'medium',
        mode = 'contained',
        type = 'button',
        color = 'primary',
        icon,
        iconAlt,
        iconPosition = 'right',
        className,
        style,
        onClick,
        id,
        'data-testid': testId,
        'aria-label': ariaLabel,
        tabIndex,
        children,
      },
      ref,
    ) => {
      const theme = useTheme();
      const border = mode === 'outlined';
      const iconOnly = !children && !!icon;
      const focusOnText = mode === 'plain';
      const fontSize = `${theme.fonts.sizes[size]}rem`;
      const baseColor = theme.colors[color.toUpperCase() as ValidThemeColor];
      const white = theme.colors.WHITE;
      const backgroundColor = mode === 'contained' ? baseColor : 'initial';
      const mainColor = mode === 'contained' ? white : baseColor;
      const focusColor =
        mode === 'outlined'
          ? Color(baseColor).lighten(0.85).hex()
          : Color(baseColor).darken(0.25).hex();

      return (
        <StyledBaseButton
          id={id}
          style={style}
          className={className}
          disabled={disabled}
          data-testid={testId}
          aria-label={ariaLabel}
          onClick={onClick}
          type={type}
          tabIndex={tabIndex}
          backgroundColor={backgroundColor}
          mainColor={mainColor}
          focusColor={focusColor}
          iconOnly={iconOnly}
          focusOnText={focusOnText}
          fontSize={fontSize}
          _fill={fill}
          border={border}
          ref={ref}>
          {icon && iconPosition === 'left' && (
            <Icon icon={icon} alt={iconAlt} />
          )}
          {children && <span>{children}</span>}
          {icon && iconPosition === 'right' && (
            <Icon icon={icon} alt={iconAlt} />
          )}
        </StyledBaseButton>
      );
    },
  ),
)``;

export default Button;
