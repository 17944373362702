export enum Locale {
  german = 'de-DE',
  english = 'en-US',
}

export const SESSION_STATE_NAME = 'personality-test-state';
export const SESSION_FEATURE_FLAGS = 'personality-test-feature-flags';
export const SESSION_ACCESS_TOKEN_NAME = 'personality-test-access-token';
export const ACCESS_TOKEN_PARAM_NAME = 'accessToken';
export const SESSION_VISITOR_ID_KEY = 'visitorId';

export enum TrackingEvent {
  languageSelect = 'languageSelect',
  questionSelect = 'questionSelect',
  questionSelectChange = 'questionSelectChange',
  ctaPressed = 'ctaPressed',

  // TODO generalize input events.
  signupFirstNameAdd = 'signupFirstNameAdd',
  signupEmailAdd = 'signupEmailAdd',
  signupEmailError = 'signupEmailError',
  signupEmailErrorRecover = 'signupEmailErrorRecover',

  modalVisible = 'modalVisible',
  modalBack = 'modalBack',
  modalCancel = 'modalCancel',

  navigation = 'navigation',
  resultShare = 'resultShare',

  errorFallback = 'errorFallback',
  errorFallbackCta = 'errorFallbackCta',
}

export enum Color {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Blue = 'blue',
}

export enum Dimension {
  Dominant = 'D',
  Influencing = 'I',
  Steady = 'S',
  Cautious = 'C',
}

export const DIMENSIONS: {
  [key in Dimension]: { id: number; color: Color };
} = {
  [Dimension.Dominant]: { id: 1, color: Color.Red },
  [Dimension.Influencing]: { id: 2, color: Color.Yellow },
  [Dimension.Steady]: { id: 3, color: Color.Green },
  [Dimension.Cautious]: { id: 4, color: Color.Blue },
};

export enum EnvironmentVariables {
  apiDomain = 'REACT_APP_API_DOMAIN',
  apiQuestionPath = 'REACT_APP_API_QUESTION_PATH',
  apiResultService = 'REACT_APP_API_RESULT_PATH',
  personalityTestDomain = 'REACT_APP_PERSONALITY_TEST_DOMAIN',
  invalidAccessPath = 'REACT_APP_INVALID_ACCESS_PATH',
  missingAccessPath = 'REACT_APP_MISSING_ACCESS_PATH',
  coursesDomain = 'REACT_APP_COURSES_DOMAIN',
}

export enum FeatureFlag {
  enableMswMocks = 'REACT_APP_START_MOCK_SERVICE_WORKER',
  enableAllRoutesForDebug = 'REACT_APP_ENABLE_ALL_ROUTES_FOR_DEBUG',
  enableProResultsTestGenerationForTesting = 'REACT_APP_ENABLE_PRO_RESULTS_TEST_GENERATION_FOR_TESTING',
  enableCoachTest = 'REACT_APP_ENABLE_COACH_TEST',
  enableEntrepreneurTest = 'REACT_APP_ENABLE_ENTREPRENEUR_TEST',
}

export type PersonalityPatterns =
  | '1'
  | '12'
  | '123'
  | '124'
  | '13'
  | '134'
  | '14'
  | '2'
  | '21'
  | '23'
  | '234'
  | '24'
  | '3'
  | '31'
  | '32'
  | '34'
  | '4'
  | '41'
  | '42'
  | '43';

export const dimensionPersonalityTypesMap: Record<
  Dimension,
  Array<PersonalityPatterns>
> = {
  [Dimension.Dominant]: ['1', '12', '13', '14', '124'],
  [Dimension.Influencing]: ['2', '21', '23', '24', '123'],
  [Dimension.Steady]: ['3', '31', '32', '34', '234'],
  [Dimension.Cautious]: ['4', '41', '42', '43', '134'],
};

export const personalityTypesToDimensionsMap: Record<
  PersonalityPatterns,
  Dimension
> = Object.entries(dimensionPersonalityTypesMap).reduce<
  Record<string, Dimension>
>((sum, [k, x]) => {
  x.forEach((y) => (sum[y] = k as Dimension));
  return sum;
}, {});
