import { forwardRef } from 'react';
import styled from 'styled-components';

export type PercentageBarProps = {
  /** A number between 0 and 100 */
  value: number;
  direction: 'left' | 'right';
  'data-testid'?: string;
  style?: React.CSSProperties;
  className?: string;
};

export const BasePercentageBar = forwardRef<HTMLDivElement, PercentageBarProps>(
  ({ value, direction, className, ...props }, ref) => (
    <div {...props} ref={ref} className={className}>
      <div
        style={{
          width: `${value}%`,
          [direction]: '0',
        }}
      />
    </div>
  ),
);

const PercentageBar = styled(BasePercentageBar)`
  position: relative;
  width: 100%;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.MEDIUM_BACKGROUND};
  border-radius: 5px;

  & > div {
    position: absolute;
    ${({ theme }) => `background: ${theme.colors.PRIMARY}`};
    height: 6px;
    border-radius: 5px;
    transition: width 500ms ease-out;
  }
`;

export default PercentageBar;
