import styled from 'styled-components';

export type TextCardProps = {
  borderSide?: 'right' | 'left';
  color?: string;
};

const TextCard = styled.div<TextCardProps>`
  ${({ borderSide = 'left', color, theme }) =>
    `border-${borderSide}: 8px solid ${color ?? theme.colors.PRIMARY}`};
  background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 4px 0;
  padding: 16px;
`;

export default TextCard;
