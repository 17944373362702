import { forwardRef } from 'react';
import styled from 'styled-components';

export interface ErrorFallbackProps {
  code: number;
  title: string;
  subtitle: string;
  message: string;
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  padding: 0 24px;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }

  p {
    font-size: 16px;
    text-align: center;
  }
`;

const Code = styled.h2`
  font-size: 240px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.PRIMARY};
  background: -webkit-${({ theme }) => theme.gradients.YELLOW_TO_GREEN_TO_BLUE};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 72px;
    margin: 0 0 16px;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.PRIMARY};
  font-size: 34px;
  font-style: ${({ theme }) => theme.fonts.family.semiBold};
  font-weight: bold;
  margin: 0 0 8px;
`;

const ErrorFallback = styled(
  forwardRef<HTMLDivElement, ErrorFallbackProps>(
    ({ code, title, subtitle, message, ...props }, ref) => {
      return (
        <Container {...props} ref={ref}>
          <Code>{code}</Code>
          <Title>{title}</Title>
          <p>{subtitle}</p>
          <p>{message}</p>
        </Container>
      );
    },
  ),
)``;

export default ErrorFallback;
