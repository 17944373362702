import useRefCallback from '../hooks/useRefCallback';
import { forwardRef, MouseEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

const SidebarCursor = styled.span`
  position: absolute;
  width: 7px;
  height: ${({ theme }) => `${theme.fonts.sizes.h4}rem`};
  transform: translate(-4px, -50%);
  background: ${({ theme }) => theme.colors.PRIMARY};
  border-radius: 7px;
  transition: top 250ms;
  z-index: 2;
`;

const SidebarNavSectionTitle = styled.a<{ active: boolean }>`
  display: block;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  font-size: ${({ theme }) => `${theme.fonts.sizes.small}rem`};
  color: ${({ theme }) => theme.colors.BLACK};
  transition: font-size 250ms;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  letter-spacing: 0.8px;
  ${({ active, theme }) =>
    active &&
    css`
      font-size: ${theme.fonts.sizes.h5}rem;
      font-weight: bold;
      color: ${theme.colors.PRIMARY};
      background: -webkit-${theme.gradients.YELLOW_TO_GREEN_TO_BLUE};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
`;

export type NavigationSidebarProps = {
  activePageId?: string;
  /**
   * The navigation buttons are `a` elements, with `href` equal to the page id.
   * Use `preventDefault` in the native click event if you wish to supress the
   * automatic link follow through
   */
  onPageClicked?: (
    e: MouseEvent<HTMLAnchorElement>,
    activePageId: string,
  ) => void;
  pages?: Array<{
    /** Used as `href` for nav anchor entries */
    id: string;
    title: string;
  }>;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  'data-testid'?: string;
};

export const BaseNavigationSidebar = forwardRef<
  HTMLDivElement,
  NavigationSidebarProps
>(
  (
    { activePageId, onPageClicked = () => {}, pages = [], className, style },
    ref,
  ) => {
    const [
      currentSectionTitleRef,
      setCurrentSectionTitleRef,
    ] = useRefCallback();
    const [positionOfCursor, setPositionOfCursor] = useState(0);

    useEffect(
      function keepTheCursorOnTheCurrentTitle() {
        // delay is used to allow new size of text element to be computed
        const timeout = setTimeout(() => {
          const top = currentSectionTitleRef?.offsetTop ?? 0;
          const height = currentSectionTitleRef?.clientHeight ?? 0;
          setPositionOfCursor(top + height / 2);
        }, 30);
        return () => clearTimeout(timeout);
      },
      [activePageId, positionOfCursor, currentSectionTitleRef],
    );

    return (
      <nav className={className} ref={ref}>
        <div>
          <SidebarCursor style={{ top: positionOfCursor }} />
          {pages.map(({ id, title }) => (
            <SidebarNavSectionTitle
              key={id}
              onClick={(e) => onPageClicked(e, id)}
              ref={id === activePageId ? setCurrentSectionTitleRef : undefined}
              href={id === activePageId ? undefined : id}
              active={id === activePageId}>
              {title}
            </SidebarNavSectionTitle>
          ))}
        </div>
      </nav>
    );
  },
);

const SidebarNav = styled(BaseNavigationSidebar)`
  box-sizing: border-box;
  & > div {
    position: sticky;
    display: flex;
    flex-direction: column;
    border-left: 1px solid ${({ theme }) => theme.colors.LIGHT_GRAY};
    justify-content: space-between;
    box-sizing: border-box;
    top: 250px;
    ${SidebarNavSectionTitle} {
      margin-bottom: ${({ theme }) => theme.spacing.sm}rem;
    }
  }
`;

export default SidebarNav;
