import * as Sentry from '@sentry/react';
import { getEnvironmentVariable } from '../utils/environment';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: getEnvironmentVariable('REACT_APP_SENTRY_DSN'),
    environment: getEnvironmentVariable('REACT_APP_ENVIRONMENT'),
    release: getEnvironmentVariable(
      'REACT_APP_WORKFLOW_ID',
      'MISSING_ENVIRONMENT_WORKFLOW_ID',
    ),
    allowUrls: ['personality-test.greator.com', 'personality-test.greator.it'],
    ignoreErrors: ['_hsq is not defined', 'leadflows'],
  });
}
