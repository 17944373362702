import { AxiosError } from 'axios';
import { ErrorObject } from 'ajv';

export interface InternalApiError {
  code: number;
  errorCode: string;
  message: string;
}

export type RestError<T extends Record<string, any> = {}> = Required<
  Pick<
    AxiosError<{
      error: T & InternalApiError;
    }>,
    'response'
  >
>;

export type ValidationErrorResponse = RestError<{
  validationError: ErrorObject[];
}>;

// Rest API errors and other types
export enum ErrorCodes {
  ResultNotFoundError = 'ResultNotFoundError',
  AccessRevokedError = 'AccessRevokedError',
  InvalidAccessIdError = 'InvalidAccessIdError',
  MissingAccessIdError = 'MissingAccessIdError',
}

export class ApiError extends Error {
  code: number;
  errorCode: string;

  constructor(restError: RestError) {
    const { error } = restError.response.data;
    super();

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.code = error.code;
    this.errorCode = error.errorCode;
    this.message = error.message;
  }
}

// Generic Errors
export class GenericError extends Error {
  errorCode: string;

  constructor(errorCode: string, message: string) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.errorCode = errorCode;
  }
}
