import { FunctionComponent, createContext, useMemo, useEffect } from 'react';
import { Route, RouteProps, useHistory, useRouteMatch } from 'react-router-dom';
import { PersonalityTestSection } from 'typings/PersonalityTest';
import { QUESTION, QUESTIONNAIRE } from 'typings/routes';
import { useTestRouteParams } from '.';
import { TestRouteActions } from './useTestRouteActions';

export interface RouteWithNextProps extends RouteProps {
  /** relative path to the route */
  path: string;
  /**  If not set directly, this prop will determined by the parent `TestSwitch` */
  next?: string;
  accessible?: boolean;
}

export type TestRouteElement = React.ReactElement<RouteWithNextProps>;

export const TestRouteContext = createContext<TestRouteActions>({
  goToNextPage: () => {},
  goToFirstPage: () => {},
  goToQuestionnaireQuestion: (
    section: PersonalityTestSection,
    index: number,
  ) => {},
});

const TestRoute: FunctionComponent<RouteWithNextProps> = ({
  next,
  accessible = true,
  ...props
}) => {
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const { type } = useTestRouteParams();
  const memoizedContext = useMemo(
    () => ({
      goToNextPage: () => push(next!),
      goToQuestionnaireQuestion: (
        section: PersonalityTestSection,
        index: number,
      ) => push(`/${type}/${QUESTIONNAIRE}/${section}/${QUESTION}/${index}`),
      goToFirstPage: () => push(path),
    }),
    [push, next, path, type],
  );

  useEffect(function resetScrollToTopOnPageChange() {
    window.scroll(0, 0);
  }, []);

  return (
    <TestRouteContext.Provider value={memoizedContext}>
      <Route {...props} />
    </TestRouteContext.Provider>
  );
};

export default TestRoute;
