import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError, ErrorCodes, GenericError } from 'typings/errors';
import { EnvironmentVariables } from 'typings/constants';

import { ErrorFallback, ErrorFallbackDefault } from 'utils/harmony-ui';
import { getEnvironmentVariable } from 'utils/environment';
import { redirectProParticipants } from 'utils/access';
import TrackingService from 'services/TrackingService';

interface SuspenseFallbackPageProps {
  error: Error | ApiError | GenericError;
  onRecover: () => void;
}

const SuspenseFallbackPage: React.FunctionComponent<SuspenseFallbackPageProps> = ({
  error,
  onRecover,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    TrackingService.trackErrorFallback(JSON.stringify(error.message));
  }, [error.message]);

  const handleOnClick = () => {
    TrackingService.trackErrorFallbackCta(window.location.href);
    onRecover();
    window.location.reload(); // Todo waht are the implications of this?
  };

  const redirect = (path: string) => {
    redirectProParticipants({ language: i18n.language, path });
  };

  // Specific ErrorCodes handling
  if (
    error instanceof GenericError &&
    error.errorCode === ErrorCodes.MissingAccessIdError
  ) {
    redirect(getEnvironmentVariable(EnvironmentVariables.missingAccessPath));
    return null;
  }

  if (
    error instanceof ApiError &&
    error.errorCode === ErrorCodes.InvalidAccessIdError
  ) {
    redirect(getEnvironmentVariable(EnvironmentVariables.invalidAccessPath));
    return null;
  }

  // More Generic Error handling given Classes
  if (error instanceof ApiError) {
    return (
      <ErrorFallback
        code={error.code}
        subtitle={t([
          `errors.${error.errorCode}.title`,
          `errors.${error.code}.title`,
          `errors.default.title`,
        ])}
        message={t([
          `errors.${error.errorCode}.message`,
          `errors.${error.code}.message`,
          `errors.default.message`,
        ])}
        title={t(`common.oops`)}
      />
    );
  }

  return (
    <ErrorFallbackDefault
      title={t(`common.ohNo`)}
      subtitle={t(`errors.default.message`)}
      buttonText={t(`common.goBack`)}
      onClick={handleOnClick}
    />
  );
};

export default SuspenseFallbackPage;
