import styled from 'styled-components';

export type VimeoVideoPlayerProps = {
  videoId: string;
  /** Hexcode without # */
  titleColor?: string;
  allowFullScreen?: boolean;
  'data-testid'?: string;
  className?: string;
  style?: React.CSSProperties;
};

const Player = styled.iframe`
  width: 100%;
  height: 100%;
`;

const VimeoVideoPlayer: React.FunctionComponent<VimeoVideoPlayerProps> = ({
  videoId,
  titleColor,
  allowFullScreen: fullscreen,
  className,
  style,
  'data-testid': testId,
}) => {
  const colorParam = titleColor ? `?color=${titleColor}` : '';
  return (
    <Player
      className={className}
      style={style}
      data-testid={testId}
      src={`https://player.vimeo.com/video/${videoId}${colorParam}`}
      frameBorder={0}
      allowFullScreen={fullscreen}
    />
  );
};

export default VimeoVideoPlayer;
