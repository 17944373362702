import { EnvironmentVariables } from 'typings/constants';
import { getEnvironmentVariable } from './environment';

export const redirectProParticipants = ({
  language,
  path,
}: {
  language: string;
  path: string;
}) => {
  window.location.replace(
    getEnvironmentVariable(EnvironmentVariables.personalityTestDomain) +
      `/${language.toLowerCase()}` +
      path,
  );
};
