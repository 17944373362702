import useResize from '../hooks/useResize';
import React, { ReactNode, useRef, useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Button from './Button';
import mergeRefs from 'react-merge-refs';

export type TextAccordionProps = {
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  visibleText?: ReactNode;
  additionalText?: ReactNode;
  compact?: boolean;
  expandText?: string;
  collapseText?: string;
  style?: React.CSSProperties;
  className?: string;
  'data-testid'?: string;
};

export const BaseTextAccordion = React.forwardRef<
  HTMLDivElement,
  TextAccordionProps
>(
  (
    {
      visibleText,
      additionalText,
      expanded = false,
      setExpanded,
      className,
      style,
      compact = false,
      'data-testid': testId,
      expandText,
      collapseText,
    },
    forwardedRef,
  ) => {
    const adaptableContainerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<number>(0);

    useEffect(
      () => setHeight(adaptableContainerRef?.current?.scrollHeight ?? 0),
      [expanded],
    );
    useResize(() =>
      setHeight(adaptableContainerRef?.current?.scrollHeight ?? 0),
    );

    const currentRef = useRef<HTMLDivElement>(null);
    const buttonHandler = (expanded: boolean) => {
      const refRect = currentRef?.current?.getBoundingClientRect().top ?? 0;
      !expanded &&
        refRect < 0 &&
        currentRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });

      return setExpanded && setExpanded(expanded);
    };

    return (
      <div
        className={className}
        style={style}
        data-testid={testId}
        ref={mergeRefs([forwardedRef, currentRef])}>
        <HeaderContainer floating={compact}>
          {visibleText}
          <Button
            onClick={() => buttonHandler(true)}
            mode="plain"
            icon="IconChevronDown"
            size="small">
            {expandText}
          </Button>
        </HeaderContainer>
        <AdaptableContainer
          ref={adaptableContainerRef}
          style={{ maxHeight: expanded ? height : 0 }}>
          {additionalText}
        </AdaptableContainer>
        <Button
          onClick={() => buttonHandler(false)}
          mode="plain"
          icon="IconChevronUp"
          size="small">
          {collapseText}
        </Button>
      </div>
    );
  },
);

const HeaderContainer = styled.div<{ floating: boolean }>`
  ${({ floating }) => (floating ? StackedHeader : FloatingHeader)};
`;

const FloatingHeader = css`
  box-sizing: border-box;
  display: inline;
  float: left;
`;

const StackedHeader = css`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > button {
    margin: 12px 0 12px;
  }
`;

const AdaptableContainer = styled.div`
  transition: max-height 0.4s cubic-bezier(0.8, 0, 0.2, 1);
  overflow: hidden;
  height: auto;
  width: 100%;
`;

const hideButtonKeyframe = keyframes`
  from {
    height: initial;
    opacity: 1;
    overflow: visible;
  }

  to {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
`;

const showButtonKeyframe = keyframes`
  from {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  to {
    height: initial;
    opacity: 1;
    overflow: visible;
  }
`;

const showAnimation = css`
  animation: ${showButtonKeyframe} 0.2s cubic-bezier(0.8, 0, 0.2, 1) forwards;
`;

const hideAnimation = css`
  animation: ${hideButtonKeyframe} 0.2s cubic-bezier(0.8, 0, 0.2, 1) forwards;
`;

const TextAccordion = styled(BaseTextAccordion)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${Button} {
    ${({ expanded }) => (expanded ? showAnimation : hideAnimation)};
    align-self: flex-end;
    display: inline-flex;
    align-items: center;
    margin: 0;

    & > svg {
      margin-left: 7px;
      width: 8px;
    }
  }

  ${HeaderContainer} {
    ${Button} {
      ${({ expanded }) => (expanded ? hideAnimation : showAnimation)};
      align-self: auto;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export default TextAccordion;
