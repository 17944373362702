import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

export interface HamburgerButtonProps {
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
}

const BAR_HEIGHT = 0.25;
const BAR_LENGTH = BAR_HEIGHT * 7;

const HamburgerButton: React.FunctionComponent<HamburgerButtonProps> = styled(
  forwardRef<HTMLButtonElement, HamburgerButtonProps>(
    ({ active, ...props }, ref) => (
      <button {...props} ref={ref}>
        <div></div>
        <div></div>
        <div></div>
      </button>
    ),
  ),
)`
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  padding: ${BAR_LENGTH * 0.25}em;
  width: ${BAR_LENGTH * 1.5}em;
  height: ${BAR_LENGTH * 1.5}em;
  border: none;
  background: none;

  & > * {
    width: ${BAR_LENGTH}em;
    height: ${BAR_HEIGHT}em;
    background-color: ${({ theme }) => theme.colors.PRIMARY};
    transition: 200ms;

    :not(:last-child) {
      margin-bottom: ${BAR_HEIGHT * 2}em;
    }
    :not(:first-child) {
      margin-top: ${BAR_HEIGHT * 2}em;
    }
  }

  &:hover,
  &:focus {
    outline: 0;
  }

  ${({ active }) =>
    active &&
    css`
      /* Rotate first bar */
      > *:nth-child(1) {
        transform: translate(0, ${(BAR_LENGTH - BAR_HEIGHT) / 2}em)
          rotate(-45deg);
      }

      /* Fade out the second bar */
      > *:nth-child(2) {
        opacity: 0;
      }

      /* Rotate last bar  */
      > *:nth-child(3) {
        transform: translate(0%, -${(BAR_LENGTH - BAR_HEIGHT) / 2}em)
          rotate(45deg);
      }
    `}
`;

export default HamburgerButton;
