import styled, { DefaultTheme } from 'styled-components';
import {
  createElement,
  forwardRef,
  PropsWithChildren,
  CSSProperties,
} from 'react';

interface ColSizes {
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

interface ColOrders {
  xsOrder?: number;
  smOrder?: number;
  mdOrder?: number;
  lgOrder?: number;
  xlOrder?: number;
}

/**
 * Returns a list of breakpoints css selector for a given breakpoint.
 * for example, for `md`, will return:
 *
 * `[data-bp="md"] > &, [data-bp="lg"] > &, [data-bp="xl"] > &`
 */
const getSelectorsForBreakpoint = (
  theme: DefaultTheme,
  key: string,
): string => {
  const firstIndex = theme.breakpointList.findIndex((item) => item === key);
  return theme.breakpointList
    .slice(firstIndex)
    .reduce(
      (sum, key) => {
        let returnValue = sum.length ? sum + `, ` : '';
        returnValue += `, [data-bp="${key}"] > &`;
        returnValue += `, ''`;

        return returnValue;
      }
    );
};

export interface ColProps extends ColSizes, ColOrders {
  /** if true, the contents of the column will be inside a flex container */
  flex?: boolean;
  tag?: 'section' | 'article' | 'div';
  container?: boolean;
  style?: CSSProperties;
  className?: string;
  id?: string;
  'data-testid'?: string;
}

const Col = forwardRef<HTMLDivElement, PropsWithChildren<ColProps>>(
  (
    {
      tag = 'div',
      xs,
      sm,
      md,
      lg,
      xl,
      xsOrder,
      smOrder,
      mdOrder,
      lgOrder,
      xlOrder,
      container,
      ...props
    },
    ref,
  ) => {
    return createElement(tag, { ...props, ref });
  },
);

export default styled(Col)`
  box-sizing: border-box;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  ${({ theme, container, ...props }) => {
    let styles = ``;
    theme.breakpointList.forEach((key) => {
      const breakpointSize = props[key as keyof ColSizes];
      const breakpointOrder = props[`${key}Order` as keyof ColOrders];
      const breakpointTheme = theme.breakpoints[key];
      let bpStyles = ``;
      if (breakpointSize) {
        const width = (breakpointSize / breakpointTheme.columns) * 100;
        bpStyles += `
          flex: 0 0 ${width}%;
          max-width: ${width}%;
        `;
      }
      if (breakpointOrder) {
        bpStyles += `
          order: ${breakpointOrder};
        `;
      }
      if (!bpStyles) {
        return;
      }
      if (container) {
        const bpSelectors = getSelectorsForBreakpoint(theme, key);
        styles += `
          ${bpSelectors} {
            ${bpStyles}
          }
        `;
      } else if (breakpointTheme.width === 0) {
        // when breakpoint width is 0, there is no need for media query.
        styles += `${bpStyles}`;
      } else {
        styles += `
          @media only screen and (min-width: ${breakpointTheme.width}px) {
            ${bpStyles}
          }
        `;
      }
    });
    return styles;
  }}
`;
