import { PropsWithChildren, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Typography from './Typography';

type StylesProps = {
  imageSize: number;
};

export type IconCardProps = {
  /**
   * An imageSrc or a React.Element that should be an image svg.
   * For the second case, a background is provided.
   */
  image: string | JSX.Element;
  imageAlt: string;
  mode?: 'horizontal' | 'vertical' | 'oneline';
  className?: string;
  style?: React.CSSProperties;
  'data-testid'?: string;
};

const Container = styled.div`
  display: flex;
  border-radius: 5px;
  align-items: center;
  position: relative;
`;

const HorizontalContainer = styled(Container)<StylesProps>`
  flex-direction: row;

  ${(props) => {
    const padding = props.theme.spacing.md;
    return css`
      padding: ${padding}rem ${padding}rem ${padding}rem 0;
      background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
    `;
  }}
`;

const VerticalContainer = styled(Container)<StylesProps>`
  flex-direction: column;

  & > div:first-child {
    background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
    width: ${({ imageSize }) => imageSize}px;
    height: ${({ imageSize }) => imageSize}px;
    border-radius: ${({ imageSize }) => imageSize / 2}px;
    z-index: 2;
  }

  & > div:last-child {
    z-index: 1;
    flex: 1;
    background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
    padding: ${({ imageSize }) => imageSize / 4}px 15px 7px 15px;
    transform: ${({ imageSize }) => `translate(0, ${-imageSize / 2}px)`};
    margin: 0 8px 8px 8px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img<StylesProps>`
  width: ${({ imageSize }) => imageSize}px;
  height: ${({ imageSize }) => imageSize}px;
`;

const IconImage = styled.div<StylesProps>`
  margin: 5px;
  width: ${({ imageSize }) => imageSize}px;
  height: ${({ imageSize }) => imageSize}px;
  border-radius: ${({ imageSize }) => imageSize / 2}px;
  background-color: ${({ theme }) => theme.colors.LIGHT_BACKGROUND};
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: ${({ imageSize }) => (imageSize * 2) / 3}px;
    height: ${({ imageSize }) => (imageSize * 2) / 3}px;
  }
`;

const Content = styled(({ oneLine, ...props }) => <Typography {...props} />)`
  overflow: hidden;
  margin: 0;

  ${(props) =>
    props.oneLine &&
    css`
      white-space: nowrap;
      & > * {
        display: inline;
        white-space: nowrap;
      }
    `}
`;

const IconCard = forwardRef<HTMLDivElement, PropsWithChildren<IconCardProps>>(
  (
    {
      image,
      imageAlt,
      mode = 'vertical',
      className,
      style,
      'data-testid': testId,
      children,
    },
    ref,
  ) => {
    const Container =
      mode === 'vertical' ? VerticalContainer : HorizontalContainer;
    const imageSize = mode === 'oneline' ? 50 : 90;

    return (
      <Container
        ref={ref}
        data-testid={testId}
        imageSize={imageSize}
        className={className}
        style={style}>
        <ImageContainer>
          {typeof image === 'string' ? (
            <Image src={image} alt={imageAlt} imageSize={imageSize} />
          ) : (
            <IconImage imageSize={40} role="img">
              {image}
            </IconImage>
          )}
        </ImageContainer>
        <Content oneLine={mode === 'oneline'} variant="body">
          {children}
        </Content>
      </Container>
    );
  },
);

export default IconCard;
