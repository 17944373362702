export enum Quadrant {
  NE,
  SE,
  SW,
  NW,
}

export const QuadrantNames = {
  [Quadrant.NE]: 'NE',
  [Quadrant.SE]: 'SE',
  [Quadrant.SW]: 'SW',
  [Quadrant.NW]: 'NW',
};

// These numbers are a bit magical.
// REFERENCE_SIZE controls the overrall dimensions of the
// butterfly graph, while REFERENCE_WING_SIZE, REFERENCE_BACKGROUND_SIZE
// were impirically determiend to "look good".
export const REFERENCE_SIZE = 400;
export const REFERENCE_WING_SIZE = REFERENCE_SIZE / 520;
export const REFERENCE_BACKGROUND_SIZE = REFERENCE_SIZE / 225;
export const ANIMATION_TIME = 200;
