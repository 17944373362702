import { memo, forwardRef } from 'react';
import styled, { CSSProperties } from 'styled-components';
import * as themeIcons from '../theme/icons';
import { ThemeIcon } from '../theme/icons';

type Size = 'tiny' | 'small' | 'medium' | 'large' | 'huge';

export type IconProps = {
  icon: ThemeIcon;
  color?: 'primary' | 'secondary' | 'grey' | 'white' | 'transparent';
  size?: Size;
  outline?: boolean;
  transparent?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  id?: string;
  'data-testid'?: string;
  style?: CSSProperties;
  role?: string;
  alt?: string;
};

export const BaseIcon = memo(
  forwardRef<SVGSVGElement, IconProps>(
    ({ icon, color = 'primary', outline, size, ...others }, ref) => {
      const IconComponent = themeIcons[icon];
      return <IconComponent ref={ref} {...others} />;
    },
  ),
);

const getSize = (size?: Size) => {
  switch (size) {
    case 'tiny':
      return 12;
    case 'small':
      return 16;
    case 'medium':
      return 20;
    case 'large':
      return 24;
    case 'huge':
      return 32;
    default:
      return 20;
  }
};

const Icon = styled(BaseIcon)<IconProps>`
  fill: none;
  stroke: ${({ theme, color = 'primary' }) => {
    switch (color) {
      case 'primary':
        return theme.colors.PRIMARY;
      case 'secondary':
        return theme.colors.SECONDARY;
      case 'transparent':
        return theme.colors.TRANSPARENT;
      case 'white':
        return theme.colors.WHITE;
      case 'grey':
        return theme.colors.DARK_GRAY;
    }
  }};
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: ${({ transparent }) => (transparent ? 0 : 1)};
  height: ${({ size }) => getSize(size)}px;
  width: ${({ size }) => getSize(size)}px;
  transition: all 250ms;

  .outline {
    stroke-width: ${({ outline }) => (outline ? 1.5 : 0)};
  }
`;

export default Icon;
