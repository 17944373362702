import { forwardRef, useRef } from 'react';
import { css } from 'styled-components';
import { IconCheck } from '../theme/icons';

import styled from 'styled-components';

export type InputProps = React.HTMLProps<HTMLInputElement> & {
  /** more to follow as we need them */
  type?: 'text' | 'email';
  checked?: boolean;
  errorMessage?: string | null;
  className?: string;
  id?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
};

const Container = styled.div`
  position: relative;
`;

const PlaceholderStyles = css<InputProps>`
  bottom: 14px;
  color: ${(props) => props.theme.colors.DARK_GRAY};
  left: 14px;
  opacity: 0.5;

  ${(props) =>
    props.errorMessage && (!props.value || props.value !== '')
      ? `bottom: 18px;`
      : ``}
`;

const TextInput = styled.input<InputProps>`
  background-color: ${(props) => props.theme.colors.LIGHT_GRAY};
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  font-size: ${(props) => `${props.theme.fonts.sizes.body}rem`};
  height: 48px;
  line-height: 21px;
  padding-left: 14px;
  width: 100%;
  transition: all 0.3s;

  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.GREEN};
  }

  ::placeholder {
    ${PlaceholderStyles}
  }

  ${(props) =>
    props.errorMessage &&
    css`
      border: 1px solid ${props.theme.colors.RED};
      padding-bottom: 22px;
    `}
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.RED};
  user-select: none;
  bottom: 9px;
  font-size: ${(props) => `${props.theme.fonts.sizes.small}rem`};
  left: 14px;
  letter-spacing: 0.1em;
  line-height: 15px;
  position: absolute;
`;

const CheckedIcon = styled(IconCheck)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 20px;
  width: 1em;
  height: 1em;
  stroke-width: 2;
  stroke: ${({ theme }) => theme.colors.SUCCESS};
  fill: none;
`;

const Input = styled(
  forwardRef<HTMLDivElement, InputProps>(
    (
      {
        type = 'text',
        errorMessage,
        placeholder,
        checked,
        value,
        as,
        className,
        style,
        'data-testid': dataTestId,
        id,
        ...nativeInputProps
      },
      ref,
    ) => {
      const inputRef = useRef<HTMLInputElement>(null);
      const handleOnClick = () => inputRef.current?.focus();
      const placeholderText =
        (errorMessage && !value) || (!errorMessage && !value)
          ? placeholder
          : '';

      return (
        <Container ref={ref} className={className} style={style} id={id}>
          <TextInput
            {...nativeInputProps}
            type={type}
            data-testid={dataTestId}
            value={value}
            errorMessage={errorMessage}
            ref={inputRef}
            placeholder={placeholderText}
          />
          {errorMessage && (
            <ErrorMessage onClick={handleOnClick}>{errorMessage}</ErrorMessage>
          )}
          {checked && <CheckedIcon data-testid="icon-checked-green" />}
        </Container>
      );
    },
  ),
)``;

export default Input;
