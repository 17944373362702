import { memo } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ANIMATION_TIME,
  Quadrant,
  REFERENCE_SIZE,
  REFERENCE_WING_SIZE,
} from './types';

export type WingProps = {
  quadrant: Quadrant;
  stroke: string;
  fill: string;
  title: string;
  /** between 0 and 100 */
  size: number;
  onClick?: (e: React.MouseEvent, quadrant: Quadrant) => void;
};

const StyledG = styled.g`
  transition: transform ${ANIMATION_TIME}ms;

  & > path {
    filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.15));
    transition: transform ${ANIMATION_TIME}ms;
  }

  & > text:first-of-type {
    font-style: normal;
    font-weight: 600;
    font-family: ${({ theme }) => theme.fonts.family.semiBold};
    font-size: 2em;
    line-height: 54px;
    text-anchor: middle;
    dominant-baseline: central;
    user-select: none;
  }

  & > text:last-of-type {
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.514286px;
    user-select: none;
  }

  &:hover > path {
    transform: scale(1.1);
  }
`;

const STROKE_WIDTH = 10;

const ButterflyWing = memo<WingProps>(
  ({ quadrant, stroke, fill, title, size, onClick }) => {
    const { fonts } = useTheme();
    const label = Math.min(Math.max(size, 0), 100);
    const boundedSize = Math.min(Math.max(size, 18), 100);
    const radius = REFERENCE_WING_SIZE * boundedSize;
    const centerShift = 4 + STROKE_WIDTH / 2;
    const [a, b] = (() => {
      switch (quadrant) {
        case Quadrant.NE:
          return [1, -1];
        case Quadrant.SE:
          return [1, 1];
        case Quadrant.SW:
          return [-1, 1];
        case Quadrant.NW:
          return [-1, -1];
      }
    })();
    const c = a + b ? 1 : 0;

    return (
      <StyledG onClick={(e) => onClick && onClick(e, quadrant)}>
        <path
          d={`
            M ${a * radius} ${b * centerShift}
            A ${radius - STROKE_WIDTH} ${radius - STROKE_WIDTH}, 
              1, 1, ${c}, 
              ${a * centerShift} ${b * radius}
            L ${a * centerShift} ${b * centerShift} Z
          `}
          stroke={stroke}
          fill={fill}
          strokeLinejoin="round"
          strokeWidth={STROKE_WIDTH}
          style={{ filter: 'url(#shadow)' }}
        />
        <text
          x={(a * REFERENCE_SIZE) / 2.42}
          y={(b * REFERENCE_SIZE) / 2.42}
          fill={stroke}
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily={fonts.family.regular}
          children={label}
          transform={`rotate(${45 - c * 90}, ${(a * REFERENCE_SIZE) / 2.42}, ${
            (b * REFERENCE_SIZE) / 2.42
          })`}
        />
        <text
          x={(a * REFERENCE_SIZE) / 2.73}
          y={(b * REFERENCE_SIZE) / 2.73}
          fill={stroke}
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily={fonts.family.regular}
          children={title}
          transform={`rotate(${45 - c * 90}, ${(a * REFERENCE_SIZE) / 2.73}, ${
            (b * REFERENCE_SIZE) / 2.73
          })`}
        />
        <filter id="shadow" x="-300%" y="-300%" width="600%" height="600%">
          <feDropShadow dx="5" dy="5" stdDeviation="5" floodOpacity="0.15" />
        </filter>
      </StyledG>
    );
  },
);

export default ButterflyWing;
