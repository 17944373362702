import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18nBackend from 'i18next-http-backend';
import * as Yup from 'yup';
import { Locale } from 'typings/constants';
import aOrAn from 'indefinite';

const INVALID = 'validation.invalid';

i18n
  .use(LanguageDetector)
  .use(I18nBackend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: Locale.german,
    supportedLngs: [Locale.english, Locale.german],
    detection: {
      order: ['querystring'],
      lookupQuerystring: 'locale',
    },
    defaultNS: 'translation',
    fallbackNS: 'translation',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      format: (value, format) => {
        switch (format) {
          case 'handle-an':
            return aOrAn(value);
          case 'handle-an-capitalize':
            return aOrAn(value, { capitalize: true });
          default:
            return value;
        }
      },
    },
  });

Yup.setLocale({
  mixed: {
    default: (values) => ({ key: INVALID, values }),
    required: (values) => ({ key: INVALID, values }),
  },
  string: {
    min: (values) => ({ key: 'validation.fieldToShort', values }),
    email: (values) => ({ key: INVALID, values }),
  },
});
